var MantleHotspot = {
  active_timeout: null,
};

MantleHotspot.get_position = function($elem) {
  var $offsetParent = $elem.offsetParent();
  var parent_width = $offsetParent.width();
  var parent_height = $offsetParent.height();

  var position = $elem.position();
  var child_top = position['top'] + ($elem.height() / 2);
  var child_left = position['left'] + ($elem.width() / 2);

  var top_percentage = child_top / parent_height * 100;
  top_percentage = top_percentage.toFixed(2);
  var left_percentage = child_left / parent_width * 100;
  left_percentage = left_percentage.toFixed(2);

  return {'top': top_percentage+'%', 'left': left_percentage+'%'};
};

MantleHotspot.update_helper = function($hotspot_helper) {
  var $span = $hotspot_helper.find('span');
  var new_position = MantleHotspot.get_position($hotspot_helper);
  var new_text = "top: " + new_position['top'] + "<br />left: " + new_position['left'];
  $span.html(new_text);

  $('body').addClass('hotspot-helper-active');
  window.clearTimeout(this.active_timeout);
  this.active_timeout = window.setTimeout(function() {
    $('body').removeClass('hotspot-helper-active');
  }, 10000);
}

MantleHotspot.add_helper = function($parent, id) {
  if (id == null) {
    id = MantleHotspot.get_id($parent);
  }
  if ($parent.find('.js-hotspot-helper[data-hotspot-helper-id="'+id+'"]').length > 0) {
    return $parent.find('.js-hotspot-helper[data-hotspot-helper-id="'+id+'"]');
  }

  var $hotspot_helper = $('<div class="js-hotspot-helper hotspot-helper" data-hotspot-helper-id="'+id+'"><span>Sup</span></div>');
  $parent.append($hotspot_helper);
  $hotspot_helper.data('hotspot-helper-id', id);
  return $hotspot_helper;
};

MantleHotspot._current_id = 1;

MantleHotspot.get_id = function($elem) {
  if (!$elem.data('hotspot-id')) {
    $elem.data('hotspot-id', MantleHotspot._current_id);
    MantleHotspot._current_id++;
  }
  return $elem.data('hotspot-id');
};

// TODO: to get the offset parent. we need to reveal the hotspot
MantleHotspot.reveal_hotspot = function($hotspot) {
  $hotspot.show();
  $hotspot.parent().show();
};

MantleHotspot.helper_is_active = function() {
  var cookie_val = JSBoot.cookie.get('MANTLE_UI_HOTSPOT');
  var url_val = JSBoot.URL.getParam('hotspot');

  if (url_val != null) {
    return true;
  }
  if (cookie_val == '0') {
    return false;
  }
  if (!!cookie_val) {
    return true;
  }
  return false;
};

(function($) {


  Drupal.behaviors.MantleHotspot = {
    attach: function(context, settings) {
      var helper_active = MantleHotspot.helper_is_active();
      if (!helper_active) {
        return;
      }

      $('body').addClass('hotspot-helper-enabled');

      var $hotspots = $('.js-hotspot', context);
      $hotspots.each(function(i, obj) {
        var $hotspot = $(obj);
        MantleHotspot.reveal_hotspot($hotspot);

        var $offsetParent = $hotspot.offsetParent();
        var parent_id = MantleHotspot.get_id($offsetParent);
        var hotspot_id = MantleHotspot.get_id($hotspot);

        var child_top = $hotspot.get(0).style.top;
        var child_left = $hotspot.get(0).style.left;

        // if child element has no left. default it to 50%
        if (_.isEmpty(child_left) && !_.isNumber(child_left)) {
          child_left = "50%";
        }

        // if child element has no top. default it to 50%
        if (_.isEmpty(child_top) && !_.isNumber(child_top)) {
          child_top = "50%";
        }

        // we already processed via hotspot
        if ($offsetParent.hasClass('hotspot-offset-parent')) {
          return;
        }

        var $hotspot_helper = MantleHotspot.add_helper($offsetParent);
        //var $hotspot_helper = MantleHotspot.add_helper($offsetParent, child_id);
        $hotspot_helper.css({'top': child_top, 'left': child_left});
        MantleHotspot.update_helper($hotspot_helper);

        $offsetParent.addClass('hotspot-offset-parent');
      });

      var $hotspot_container = $('.js-hotspot-container', context);
      $hotspot_container.each(function(i, obj) {
        var $container = $(obj);
        var $offsetParent = $container.offsetParent();

        // we already processed via hotspot
        if ($offsetParent.hasClass('hotspot-offset-parent')) {
          return;
        }

        var parent_id = MantleHotspot.get_id($offsetParent);
        var container_id = MantleHotspot.get_id($container);
        var child_top = '10%';
        var child_left = '50%';

        var $hotspot_helper = MantleHotspot.add_helper($offsetParent, parent_id);
        $hotspot_helper.css({'top': child_top, 'left': child_left});
        MantleHotspot.update_helper($hotspot_helper);

        $offsetParent.addClass('hotspot-offset-parent');
      });

      var $offsetParents = $('.hotspot-offset-parent', context);

      $offsetParents.on('click', function(e) {
        e.preventDefault();
        var $offsetParent = $(this);
        var $hotspot_helper = MantleHotspot.add_helper($offsetParent);
        var parent_offset = $offsetParent.offset();
        var offsetY = e.pageY - parent_offset['top'];
        var offsetX = e.pageX - parent_offset['left'];

        $hotspot_helper.css({'top': offsetY + 'px', 'left': offsetX + 'px'});
        MantleHotspot.update_helper($hotspot_helper);
      });
    },
  };

})(jQuery);
