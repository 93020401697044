/**
 * Attaches colorbox for the playback of Mantle videos.
 *
 * Templates loading this library need the following:
 *     Data attribute 'play-inline' that corresponds to a checkbox value.
 *     .js-video-block as a class on the template wrapper
 *     See example in: editorial/video_block_v1
 */

var MantleBehavior = MantleBehavior || MantleBehavior;
var MantleVideo = MantleVideo || {};

(function($, Drupal, generic, loadjs) {

  // video-ended-final runs after certain async commands that run at
  // the end of video playback.
  $(document).on('video-ended-final', '#colorbox.colorbox--video', function() {
    $.colorbox.close();
  });

  var iPhone = /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  var iPad = /Pad/.test(navigator.userAgent) && !window.MSStream;
  var iOS = iPhone || iPad;

  Drupal.behaviors.videoBlock = MantleBehavior.create({
    targetSelector: '.js-video-block',

    /*
     * Load the videojs libraries async.
     */
    loadVideoJS: function(callback) {
      // If the loadAssets are not set. Assume that the flag to lazyload
      // video.js libs is not set and the libraries are already loaded.
      if (!Drupal.settings.mantle_video || !Drupal.settings.mantle_video.loadAssets) {
        callback();
        return;
      }

      var loadAssets = Drupal.settings.mantle_video.loadAssets;

      if (!loadjs.isDefined('videojs')) {
        loadjs(loadAssets, 'videojs', {
          async: true
        });
      }
      loadjs.ready('videojs', callback);
    },
    /*
     * Lazyload videojs libs before we start the real lazy attach.
     */
    attachLazyTarget: function(target) {
      var mod = this;
      this.loadVideoJS(function() {
        mod.attachLazyTargetAfterLoadJs(target);
      });
    },
    /*
     * The real lazyload attach. At this point the video.js libs are guaranteed
     * to be loaded.
     */
    // eslint-disable-next-line complexity
    attachLazyTargetAfterLoadJs: function(target) {
      var $module = $(target);

      var $videoPlayer = MantleVideo.MantleVideoPlayer.getVideoPlayerDom($module);
      var mantleVideoPlayer = new MantleVideo.MantleVideoPlayer($videoPlayer);

      var $videoElement = mantleVideoPlayer.$videoElement;
      var $videoJS = $('elc-video-js, .video-js', $videoElement);
      var playInline = $module.data('play-inline');

      // Need an actual video
      if ($videoElement.length === 0) {
        return;
      }

      /*
       * We want the youtube iframe initialized so we can click to play. But only
       * when the video comes into view.
       *
       * This is for iOS which has strict rules about the timing between a click
       * and when a video plays.
       */
      if (iPhone || (playInline && iPad)) {
        var elcVideo = $videoElement.data('video-manager');
        elcVideo.init();
      }

      // Video playback in overlay is the default.
      // See if we need to take over the start-video event to handle overlay.
      if (playInline) {
        return;
      }

      if (iPhone) {
        // on IOS default to playing in full screen
        $videoJS.removeAttr('playsinline');
      }

      $module.off('start-video.videoBlock').on('start-video.videoBlock', '.js-mantle-video-player', function(e) {
        var $videoPlayer = $(this);
        var $videoElement = $('.js-videojs-video', $videoPlayer);
        var elcVideo = $videoElement.data('video-manager');

        // Don't let the top level inline stuff handle video blocks.
        e.stopPropagation();

        if (iPhone) {
          // At this point. We've setup video to not play inline and
          // preloaded the iframe via intersection observer. This should
          // pop the fullscreen view.
          elcVideo.startVideo();
          return;
        }

        var mantleVideoPlayerClone = mantleVideoPlayer.clone();
        var $videoPlayerClone = mantleVideoPlayerClone.$videoPlayer;
        var cloneController = mantleVideoPlayerClone.videoElementController;

        var options = {
          content: $videoPlayerClone,
          className: 'colorbox--video',
          height: 'auto',
          width: '100%',
          maxWidth: 1024,
          maxHeight: 768,
          scrolling: false,
          onComplete: function() {
            // So it's "possible" for ipad to play on overlay click. Same
            // with iphone. But it comes down to a speed issue. Also, videojs
            // currently doesn't handle getting the extra -1 youtube state
            // event well.
            //
            // NOTE: once mantle_video is in colorbox. it won't be caught by
            // start-video.videoElement. Which is what we want.
            cloneController.init();
            if (!iPad) {
              cloneController.startVideo();
            }
          }
        };

        generic.overlay.launch(options);
      });
    },
  });
})(jQuery, Drupal, window.generic || {}, window.loadjs);
