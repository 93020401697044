var JSBoot = JSBoot || {};
var SiteOverlayManager = SiteOverlayManager || {};
var MantleMultiLang = MantleMultiLang || {};
var site = site || {};

var OverlayLinksManager = (function(OverlayLinksManager, Drupal, $) {
  // Load once
  if (!!OverlayLinksManager.loaded) {
    return OverlayLinksManager;
  }

  OverlayLinksManager.loaded = true;

  OverlayLinksManager.getFileExtension = function(href) {
    var urlParts = JSBoot.URL.urlToParts(href);
    var pathname = urlParts.pathname;
    var ext = pathname.split('.').pop();
    return ext;
  };

  /*
   * Basically if we have room. Show the image in its full size.
   */
  OverlayLinksManager.fixColorBoxPhoto = function() {
    // wth colorbox.
    var $colorboxImage = $('#colorbox .cboxPhoto');

    if ($colorboxImage.length === 0) {
      return;
    }

    $colorboxImage.css(
      {
        marginTop: 0,
        width: 'auto',
        height: 'auto',
      }
    );

    var resizeOptions = {};
    var naturalWidth = $colorboxImage.width();
    var windowWidth = $(window).width();
    var colorboxWidth = $('#cboxLoadedContent').width();

    // Image is larger than window. Likely mobile.
    if (naturalWidth > windowWidth) {
      $colorboxImage.css(
        {
          width: '100%',
        }
      );
      resizeOptions.width = '100%';
    }

    // Imge is smaller than window but naturally bigger than current box.
    if (naturalWidth < windowWidth && naturalWidth > colorboxWidth) {
      resizeOptions.innerWidth = naturalWidth;
    }
    $.colorbox.resize(resizeOptions);
  };

  /*
   * Create classname from special data attributes.
   */
  OverlayLinksManager.extractClassFromLink = function($link) {
    var classes = [];

    var overlayStyleKey = $link.data('overlay-style-key');
    var overlayClass = $link.data('overlay-class');

    if (overlayStyleKey) {
      classes.push(overlayStyleKey);
    }

    if (overlayClass) {
      classes.push(overlayClass);
    }

    var className = classes.join(' ');
    return className;
  };

  /*
   * Handle cases where the link's href points to the content we want in the
   * overlay.
   */
  OverlayLinksManager.handleLinkedContent = function(e, $link) {
    var href = $link.attr('href');

    var overlayOptions = OverlayLinksManager.getBaseOverlayOptions($link);
    overlayOptions.href = href;

    var fileExt = OverlayLinksManager.getFileExtension(href);

    // Allow explicit triggering of iframe.
    var iFrameAttr = $link.data('iframe');
    var isIFrame = false;

    // pdf needs iframe.
    if (fileExt === 'pdf') {
      isIFrame = true;
      // For PDF which can't be responsive. Let's not use an overlay. Just let
      // the link work
      if ($(window).width() < 640) {
        return;
      }
    }

    // Allow data-iframe="1" to override;
    if (typeof iFrameAttr !== 'undefined') {
      isIFrame = !!iFrameAttr;
    }

    if (isIFrame) {
      overlayOptions.iframe = true;
      overlayOptions.height = '95%';
      overlayOptions.width = '95%';
    }

    overlayOptions.onComplete = function() {
      OverlayLinksManager.fixColorBoxPhoto();
    };

    OverlayLinksManager.requestOverlayForLink(overlayOptions, $link);

    e.preventDefault();
  };

  /*
   * Handle cases where the link has the overlay content attached in a data
   * attr or if we point to another element on page to grab content.
   */
  OverlayLinksManager.handleInlinedContent = function(e, $link) {
    var overlayContent = $link.data('overlay-content');
    var overlayContentSelector = $link.data('overlay-content-selector');

    if (!overlayContent && overlayContentSelector) {
      var $overlayContentProvider = $(overlayContentSelector);
      overlayContent = $overlayContentProvider.html();
    }

    var overlayOptions = OverlayLinksManager.getBaseOverlayOptions($link);
    overlayOptions.html = overlayContent;

    OverlayLinksManager.requestOverlayForLink(overlayOptions, $link);

    e.preventDefault();
  };

  /*
   * Handle content_key provided by MantleMultiLang. This can either be a
   * conf key or a nid.
   */
  OverlayLinksManager.handleContentKey = function(e, $link) {
    var contentKey = $link.data('overlay-content-key');

    var contentOptions = {};
    contentOptions.success = function(data, responseText, req) {
      var overlayOptions = OverlayLinksManager.getBaseOverlayOptions($link);
      overlayOptions.html = data.content;

      OverlayLinksManager.requestOverlayForLink(overlayOptions, $link);

      e.preventDefault();
    };
    MantleMultiLang.getTranslatedContent(contentKey, contentOptions);
  };

  /*
   * Handle overlays that uses an inline template for content.
   */
  OverlayLinksManager.handleInlineTemplate = function(e, $link) {
    var templateKey = $link.data('overlay-content-template');
    var templateContent = null;

    // Attempt to get the template content.
    try {
      templateContent = site.template.get({name: templateKey});
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Cannot open template overlay. Missing template: ' + templateKey);
      return;
    }

    var overlayOptions = OverlayLinksManager.getBaseOverlayOptions($link);
    overlayOptions.html = templateContent;

    OverlayLinksManager.requestOverlayForLink(overlayOptions, $link);

    e.preventDefault();
  };

  /*
   * Central util function to launch overlays. We attach events and triggers
   * here to make it easier for other components to intergrate with the
   * lifecycle.
   */
  OverlayLinksManager.requestOverlayForLink = function(overlayOptions, $link) {
    // Launch overlay
    var meta = {
      id: 'overlay-link',
      weight: 10,
    };

    // Allow us to notify other components that this overlay has been closed.
    overlayOptions.onClosed = function(e) {
      var metadata = {
        trigger: $link,
        className: overlayOptions.className,
      };
      $(document).trigger('OverlayLinksManager:Closed', metadata);
    };

    SiteOverlayManager.requestOverlay(overlayOptions, meta);
  };

  OverlayLinksManager.getBaseOverlayOptions = function($link) {
    var className = OverlayLinksManager.extractClassFromLink($link);
    var overlayOptions = {
      className: className,
      fixed: true,
      maxWidth: '96%',
      maxHeight: '90%',
    };
    return overlayOptions;
  };

  $(document).off('.mantleOverlayLinks');
  // eslint-disable-next-line complexity
  $(document).on('click.mantleOverlayLinks', 'a.js-mantle-overlay-link', function(e) {
    var $link = $(this);

    // Handle overlay links that href to the content that needs to be
    // overlayed.
    if ($link.attr('href')) {
      OverlayLinksManager.handleLinkedContent(e, $link);
      return;
    }

    if ($link.data('overlay-content') || $link.data('overlay-content-selector')) {
      OverlayLinksManager.handleInlinedContent(e, $link);
      return;
    }

    if (MantleMultiLang.getTranslatedContent && $link.data('overlay-content-key')) {
      OverlayLinksManager.handleContentKey(e, $link);
      return;
    }

    if ($link.data('overlay-content-template') && site.template && site.template.get) {
      OverlayLinksManager.handleInlineTemplate(e, $link);
      return;
    }
  });

  return OverlayLinksManager;
})(OverlayLinksManager || {}, Drupal, jQuery);
