var prodcat = prodcat || {};
prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

var site = site || {};
var MantlePD = MantlePD || {};
var JSBoot = JSBoot || {};

var TFBadges = (function(TFBadges, $) {
  TFBadges.getFlagKeysFromProductId = function (productId) {
    var prodData = prodcat.data.getProduct(productId);

    if (!prodData) {
      return;
    }

    // Get the settings we need.
    var badgeMappingConfig = Drupal.settings.consensus_badge_mapping;
    var consensus = MantlePD.derive_consensus(prodData, prodData.skus);
    var pdManifest = {
      consensus: consensus
    };
    var autoFlagHash = TFBadges.deriveAutoBadges(pdManifest, badgeMappingConfig);
    var flagHash = TFBadges.generateBaseFlagHash(prodData);
    // Munge both .net and auto flags together

    _.extend(flagHash, autoFlagHash);

    var flags = _.keys(flagHash);

    return flags;
  };
  /*
   * Get the current auto badges based on $dom.
   */
  TFBadges.getCurrentBadges = function($product) {
    var $auto_badges = $product.find('.js-product-brief-badge[data-auto-badge]');

    // Get the current auto badges reflected in the dom.
    var current_auto_badges = [];
    $auto_badges.each(function(i, obj) {
      var $badge = $(obj);
      var auto_badge_key = $badge.data('flag-key');
      current_auto_badges.push(auto_badge_key);
    });
    return current_auto_badges;
  };

  /*
   * Similar to toofaced_product_add_flags_from_consensus in
   * toofaced_product.badging.inc. Get auto badges from consensus fields.
   */
  TFBadges.deriveAutoBadges = function(pd_manifest, badge_mapping_config) {
    // Get auto flags based on consensus values.
    var consensus = pd_manifest.consensus;
    var result = MantlePD.derive_all_fields(consensus, badge_mapping_config);

    var auto_flag_hash = {};
    for (var k in result) {
      var flag_value = result[k];
      if (!!flag_value) {
        auto_flag_hash[flag_value] = 'auto';
      }
    }

    return auto_flag_hash;
  };

  /*
   * Did the auto badges change and require a rerender.
   */
  TFBadges.badgesChanged = function(current_auto_badges, new_auto_badges) {
    // check for changes in auto flags. Only need to do work if auto flags
    // changed.
    // obvs no change here
    if (new_auto_badges.length === 0 && current_auto_badges.length === 0) {
      return false;
    }

    // check if same length has the same values.
    if (new_auto_badges.length === current_auto_badges.length) {
      var diff = _.difference(new_auto_badges, current_auto_badges);
      if (diff.length === 0) {
        return false;
      }
    }

    return true;
  };

  /*
   * Generate Flag Hash from .net data. Does not include the auto badges.
   */
  TFBadges.generateBaseFlagHash = function(prodData) {
    var flag_hash = {};

    var flags = TFBadges.getStaticFlags(prodData);
    if (flags.length > 0) {
      for (var i = 0; i < flags.length; i++) {
        var flag = flags[i];
        flag = flag.trim();
        flag_hash[flag] = true;
      }
    }

    return flag_hash;
  };

  /*
   * Get the .net flags as an array. PROD/SKU product ids are treated
   * differently.
   */
  TFBadges.getStaticFlags = function(prodData) {
    var flags = [];
    var base_flag_string = prodData.MISC_FLAG_TEXT;

    // For now, if the product is a sku. Don't use product level flags.
    if (prodData.sku_as_product) {
      base_flag_string = prodData.defaultSku.MISC_FLAG_TEXT;
    }

    if (typeof base_flag_string === 'string') {
      flags = base_flag_string.split(',');
    }
    return flags;
  };

  /*
   * From the flag_hash which defined what flags are active. We use the
   * badge_ts_pack to get render variables, ordering, section placement.
   */
  TFBadges.generateBadgeItems = function(flag_hash, badge_ts_pack) {
    var badges = [];

    // we loop by badge_ts_pack because it is order in the way the brand wants.
    // this is controlled via a translation set item
    for (var i = 0; i < badge_ts_pack.length; i++) {
      var item = badge_ts_pack[i];
      item = _.extend({}, item); // make copy
      var key = item.key;
      if (!key) {
        continue;
      }
      if (!(key in flag_hash)) {
        continue;
      }
      if (flag_hash.key === 'auto') {
        item.auto = true;
      }
      badges.push(item);
    }

    TFBadges.addBadgePosition(badges);

    return badges;
  };

  /*
   * Simple util to add badge position by ordinal pos.
   */
  TFBadges.addBadgePosition = function(badges) {
    // add position
    for (var j = 0; j < badges.length; j++) {
      var badge = badges[j];
      if (j < 2) {
        badge.side_badge = true;
      } else {
        badge.bottom_badge = true;
      }
    }
    return badges;
  };

  /*
   * Main func to rerender the product badges if auto badging changed based on
   * L2 data.
   */
  TFBadges.updateBadges = function($product) {
    var updatePack = this.getUpdatePack($product);

    if (updatePack === false) {
      return;
    }

    var $flag_container = updatePack.$flag_container;
    var badge_ts_pack = updatePack.badge_ts_pack;
    var badge_mapping_config = updatePack.badge_mapping_config;
    var pd_manifest = updatePack.pd_manifest;

    var auto_flag_hash = this.deriveAutoBadges(pd_manifest, badge_mapping_config);
    var new_auto_badges = _.keys(auto_flag_hash);

    var current_auto_badges = this.getCurrentBadges($product);

    // TODO: we could change this to check the derived flag_hash. In case we
    // wanted to always dynamically render badges and thus need to support .net
    // flags. Right now we support changing auto flags.
    var changed = this.badgesChanged(current_auto_badges, new_auto_badges);

    if (!changed) {
      return;
    }


    // Gimme dat debug pls.
    if (!!JSBoot.cookie.get('badge-debug')) {
      // eslint-disable-next-line no-console
      console.log('Badges were changed from L2', current_auto_badges, new_auto_badges);
    }

    // At this point we either have different sized arrays or same sized with
    // different values.

    var prodData = MantlePD.getProductFromEl($product);

    var flag_hash = this.generateBaseFlagHash(prodData);
    // Munge both .net and auto flags together
    _.extend(flag_hash, auto_flag_hash);

    var badges = this.generateBadgeItems(flag_hash, badge_ts_pack);

    var rendered = site.template.get({
      name: 'product_flag',
      data: {'PRODUCT_BADGES': badges},
    });

    $flag_container.html(rendered);
  };

  /*
   * Return san updatePack or false if the $product is not capable of auto
   * updating badges from L2.
   */
  TFBadges.getUpdatePack = function($product) {
    var updatePack = {};

    var $flag_container = $product.find('.js-product-brief-flags');

    if (!$product.hasClass('js-product-brief')) {
      return false;
    }

    if (!$flag_container.length) {
      return false;
    }

    // Get the settings we need.
    var badge_ts_pack = Drupal.settings.product_badge_mpp_ts;
    var badge_mapping_config = Drupal.settings.consensus_badge_mapping;

    if (!badge_ts_pack || !badge_mapping_config) {
      return false;
    }

    var pd_manifest = $product.data('pd-manifest');

    if (!pd_manifest) {
      return false;
    }

    updatePack.$flag_container = $flag_container;
    updatePack.badge_ts_pack = badge_ts_pack;
    updatePack.badge_mapping_config = badge_mapping_config;
    updatePack.pd_manifest = pd_manifest;

    return updatePack;
  };

  /*
   * Update Badges dynamically.
   */
  $(document).off('.badges');
  $(document).on('pd_manifest_updated.badges', '.js-product', function(e) {
    var $product = $(this);
    TFBadges.updateBadges($product);
  });

  return TFBadges;
})(TFBadges || {}, jQuery);
