var prodcat = prodcat || {};
prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
var MantlePD = MantlePD || {};
var MantleGrid = MantleGrid || {};
var MantlePerf = MantlePerf || {};
var MantleFEImages = MantleFEImages || {};
var JSBoot = JSBoot || {};

/* eslint-disable complexity */
(function ($, generic) {
  /*
   * Adds SKUs to cart.
   */
  prodcat.ui.addToCart = function (args) {
    var skuBaseId;
    if (args.skuData && args.skuData.SKU_BASE_ID) {
      skuBaseId = args.skuData.SKU_BASE_ID;
    } else if (args.skuBaseId) {
      skuBaseId = args.skuBaseId;
    } else {
      return;
    }

    var quantity;
    if (typeof args.quantity !== 'undefined') {
      quantity = args.quantity;
    } else {
      quantity = 1;
    }

    var catBaseId = '';
    if (args.skuData && args.skuData.PARENT_CAT_ID) {
      var matchResult = args.skuData.PARENT_CAT_ID.match('[0-9]+');
      if (matchResult) {
        catBaseId = matchResult[0];
      }
    }

    args.skus = args.skus || (_.isString(skuBaseId) ? [skuBaseId] : skuBaseId);
    args.itemType = args.itemType || 'cart';
    args.QTY = quantity || args.QTY;

    // Conditionally add a CAT_BASE_ID key to the list of parameters to send
    var cbid = args.CAT_BASE_ID || catBaseId;
    if (cbid.length > 0) {
      args.CAT_BASE_ID = cbid;
    }

    generic.checkout.cart.updateCart({
      params: args,
      onSuccess: function (r) {
        var resultData = r.getData();
        $(document).trigger('addToCart.toggle', [args.$addBtn]);
        $(document).trigger('addToCart.success', [resultData]);
      },
      onFailure: function (ss) {
        var errorObjectsArray = ss.getMessages();
        $(document).trigger('addToCart.toggle', [args.$addBtn]);
        $(document).trigger('addToCart.failure', [errorObjectsArray]);
      }
    });
  };

  /*
   * Because prodcat.ui.addToCart relies on updateCart which hasn't really been
   * updated in a long time. We need a kit specific add to cart method. Super
   * annoying.
   */
  prodcat.ui.addToCartKit = function (args) {
    // per jim butler should not have skuBaseId for kits.
    delete args.skuBaseId;

    generic.jsonrpc.fetch({
      method: 'rpc.form',
      params: [args],
      onSuccess: function (r) {
        var resultData = r.getData();
        $(document).trigger('addToCart.toggle', [args.$addBtn]);
        $(document).trigger('addToCart.success', [resultData]);
      },
      onFailure: function (ss) {
        var errorObjectsArray = ss.getMessages();
        $(document).trigger('addToCart.toggle', [args.$addBtn]);
        $(document).trigger('addToCart.failure', [errorObjectsArray]);
      }
    });
  };

  /*
   * Adds a SKU to the user's primary favorites list.
   * @param {Object} args
   * @param {Object} args.skuData a set of key-value pairs describing a SKU
   * @param {String, Number} args.skuData.SKU_BASE_ID Base ID for a SKU (numerical only, i.e. no 'SKU' prefix)
   * @param {String} args.skuData.PARENT_CAT_ID Category ID for the SKU
   * @param {String, Number} args.skuBaseId Base ID for a SKU (numerical only, i.e. no 'SKU' prefix)
   */
  prodcat.ui.addToFavorites = function (args) {
    var params = {
      _SUBMIT: 'alter_collection',
      action: 'add'
    };

    var skuBaseId;
    if (args.skuData && args.skuData.SKU_BASE_ID) {
      skuBaseId = args.skuData.SKU_BASE_ID;
    } else if (args.skuBaseId) {
      skuBaseId = args.skuBaseId;
    } else {
      return;
    }
    params.SKU_BASE_ID = skuBaseId;

    if (args.skuData && args.skuData.PARENT_CAT_ID) {
      var matchResult = args.skuData.PARENT_CAT_ID.match('[0-9]+');
      if (matchResult) {
        params.CAT_BASE_ID = matchResult[0];
      }
    }
    if (args.action) {
      params.action = args.action;
    }

    var $el = args.$el;

    args.$el.addClass('favorites-loading');

    generic.jsonrpc.fetch({
      method: 'rpc.form',
      params: [params],
      onSuccess: function (jsonRpcResponse) {
        // add fake delay for animation
        window.setTimeout(function () {
          args.$el.removeClass('favorites-loading');
          var d = jsonRpcResponse.getData();
          var r = d.ac_results[0].result;
          var cr;

          if (params.action !== 'delete') {
            if (r.KEY === 'SKU_ALREADY_IN_COLLECTION.ADD_SKU.COLLECTION.SAVE') {
              cr = jsonRpcResponse.getCartResults();
              $(document).trigger('addToWishlist.exists', [cr, args.$el]);
            } else if (r.SUCCESS === 1 || r.KEY === 'SUCCESS.ADD_SKU.COLLECTION.SAVE') {
              cr = jsonRpcResponse.getCartResults();
              $(document).trigger('addToWishlist.success', [cr, args.$el]);
            }
            $(document).trigger('product.favorites', [args.$el, 'success', skuBaseId]);
            $(document).trigger('product.favorites_rpc', ['success', skuBaseId]);
          } else {
            $(document).trigger('product.favorites', [args.$el, 'removed', skuBaseId]);
            $(document).trigger('product.favorites_rpc', ['removed', skuBaseId]);
          }
        }, 500);
      },
      onFailure: function (ss) {
        args.$el.removeClass('favorites-loading');
        var errorObjectsArray = ss.getMessages();
        $(document).trigger('addToWishlist.failure', [errorObjectsArray]);
      }
    });
  };

  // Helper func to tell if cta is disabled
  prodcat.ui.isProductCtaDisabled = function ($btn) {
    if ($btn.data('disabled') || $btn.hasclass('button--disabled')) {
      return true;
    }
    return false;
  };

  prodcat.ui.handleWaitlistFormVisibility = function (hideWaitlist) {
    var scenario = {
      $SPP: $('.js-add-to-cart-row'),
      $MPP: $('.js-add-to-cart-quickshop-row')
    };
    var isMobile = Unison.fetch.now().name === 'small';
    var $isShadedSPP = $('.js-product-shade-picker').length > 0;
    var $waitlistFormMobile = $('.js-waitlist-mobile');
    var $waitlistContainer = $('.js-waitlist-container');
    var $waitlistFormContainer = $('.js-waitlist-form-container', $waitlistContainer);
    var $waitlistEmail = $('.js-waitlist-email', $waitlistFormContainer);
    var $waitlistErrorMessagesUL = $('.js-error-messages', $waitlistFormContainer);
    var $body = $('body');

    if (hideWaitlist) {
      // Hide waitlist form
      if (scenario.$SPP && scenario.$SPP.length) {
        scenario.$SPP.removeClass('hidden');
      } else if (scenario.$MPP && scenario.$MPP.length) {
        scenario.$MPP.removeClass('hidden');
      }
      if (isMobile) {
        $body.removeClass($isShadedSPP ? 'page-product--waitlist-shaded' : 'page-product--waitlist');
      }
      $waitlistFormMobile.removeClass('waitlist-form');
      $waitlistContainer.addClass('hidden');
      $waitlistFormContainer.addClass('hidden');
    } else {
      // Show waitlist form
      if (scenario.$SPP && scenario.$SPP.length) {
        scenario.$SPP.addClass('hidden');
      } else if (scenario.$MPP && scenario.$MPP.length) {
        scenario.$MPP.addClass('hidden');
      }
      if (isMobile) {
        $body.addClass($isShadedSPP ? 'page-product--waitlist-shaded' : 'page-product--waitlist');
      }
      $waitlistFormMobile.addClass('waitlist-form');
      $waitlistContainer.removeClass('hidden');
      $waitlistFormContainer.removeClass('hidden');
      if ($waitlistEmail.val()) {
        $waitlistEmail.val('');
      }
      if ($waitlistEmail.hasClass('error')) {
        $waitlistEmail.removeClass('error');
        $waitlistErrorMessagesUL.empty();
      }
    }
  };

  // Helper func to set product cta as enabled
  prodcat.ui.enableProductCta = function ($btn, $product) {
    if ($btn && $btn.length > 0) {
      $btn.removeClass('button--disabled');
      $btn.data('disabled', false);
      $btn.removeAttr('aria-disabled');
    }
    if ($product && $product.length > 0) {
      $product.removeClass('add-to-bag-cta-disabled');
      if ($product.hasClass('product-full')) {
        prodcat.ui.handleWaitlistFormVisibility(true);
      }
    }
  };

  // Helper func to set product cta as disabled
  prodcat.ui.disableProductCta = function ($btn, $product) {
    if ($btn && $btn.length > 0) {
      $btn.addClass('button--disabled');
      $btn.data('disabled', true);
      $btn.attr('aria-disabled', 'true');
    }
    if ($product && $product.length > 0) {
      $product.addClass('add-to-bag-cta-disabled');
      if ($product.hasClass('product-full')) {
        prodcat.ui.handleWaitlistFormVisibility(false);
      }
    }
  };

  $(document).on('product.favorites', function (e, $el, action, skuBaseId, disable_tooltip) {
    if (action === 'success') {
      $el.addClass('favorite-added');
    }

    if (action === 'removed') {
      $el.removeClass('favorite-added');
    }

    if (!disable_tooltip) {
      $el.tooltipster('enable').tooltipster('show');
    }
  });

  /*
   * Shorthand for updating the favorites state without triggerin tooltip.
   */
  $(document).on('product:favorite:update', function (e, skuBaseId, state) {
    var $favButton = $('.js-add-to-favorites[data-sku-base-id="' + skuBaseId + '"]');
    $(document).trigger('product.favorites', [$favButton, state, skuBaseId, true]);
  });

  prodcat.ui.grids = (function () {
    var grids = [];
    return $.extend(prodcat.ui.grids || {}, {
      add: function (grid) {
        if (_.indexOf(grids, grid) === -1) {
          // if we can't find the grid in our list
          grids.push(grid);
        }
      },
      clean: function (args) {
        if (args.before && typeof args.before === 'function') {
          args.before(grids);
        }
        site.util.grids.attach(grids);
        if (args.after && typeof args.before === 'function') {
          args.after(grids);
        }
        grids = [];
      }
    });
  })();

  /*
   * This is a silly use of a router but, try to get a matching sku from a
   * product (prodData).
   */
  prodcat.ui.getSkuFromUrl = function (prodData) {
    var skuBaseId = null;

    var routerAction = {
      changeSku: function (skuID) {
        var skuData = prodcat.data.getSku(skuID);
        if (skuData) {
          skuBaseId = skuID;
        }
      },
      changeSppShade: function (shadeName) {
        prodcat.ui.routeNoSpaces = decodeURIComponent(shadeName).split('_').join(' ');

        _.each(prodData.skus, function (sku) {
          if (prodcat.ui.routeNoSpaces === sku.SHADENAME) {
            skuBaseId = sku.SKU_BASE_ID;
          }
        });
      }
    };

    var routes;
    if (!!prodData && prodData.shaded) {
      routes = {
        '/shade/:shadeName': routerAction.changeSppShade
      };
    } else {
      routes = {
        '/sku/:skuID': routerAction.changeSku
      };
    }

    // run router logic.
    var router = Router(routes);
    router.init();

    return skuBaseId;
  };

  // Default product listeners:

  $(document).on('product:init', '.js-product', function () {
    var $product = $(this);
    var prodData = prodcat.data.getProduct($product.data('product-id'));
    var routePrefix = !!prodData && prodData.shaded ? '/shade/' : '/sku/';
    var hash = location.hash;
    var routeStringAfter = hash.split('#' + routePrefix)[1];
    var isSPP = $product.hasClass('product-full');
    var isQuickShop = $product.hasClass('js-quickshop');
    var skuBaseId;

    // Check for sku routing in the SPP url
    if (isSPP) {
      skuBaseId = /^\d+$/.test(routeStringAfter) ? routeStringAfter : '';
    } else {
      skuBaseId = $product.attr('data-sku-base-id')
        ? $product.attr('data-sku-base-id')
        : $product.data('sku-base-id');
    }

    if (!skuBaseId && !!prodData && (isQuickShop || (isSPP && prodData.skus.length == 1))) {
      if (prodData.defaultSku) {
        skuBaseId = prodData.defaultSku.SKU_BASE_ID;
      }

      // if defaultSku is Sold Out, pick first shoppable sku
      if (!prodData.defaultSku.isShoppable && prodData.skus.length > 1) {
        var validSku = _.findWhere(prodData.skus, { isShoppable: 1 });
        skuBaseId = !!validSku ? validSku.SKU_BASE_ID : skuBaseId;
      }
    }

    // Only setup Router for SPP page
    if (isSPP) {
      var routerSku = prodcat.ui.getSkuFromUrl(prodData);
      if (routerSku) {
        skuBaseId = routerSku;
      }
    }

    // Select the default sku if set:
    if (!!skuBaseId) {
      $(this).trigger('product.skuSelect', [skuBaseId]);
      // MTA-4222 trigger analytics event on SPP page load
      if (isSPP && site && site.elcEvents) {
        site.elcEvents.addListener('tealium:loaded', function () {
          $(document).trigger('productSkuSelectOnLoad', [skuBaseId]);
        });
      }
    } else {
      // update inventory status:
      $(this).trigger('product.updateInvStatus');
    }
  });

  $(document).on('product.updateInvStatus', '.js-product', function () {
    var $product = $(this);
    var $addBtn = $('.js-add-to-cart, .js-add-to-bag', $product);
    var $addAllBtn = $('.js-add-all-to-cart', $product);

    if (!$addBtn.length || $addAllBtn.length) {
      return;
    }

    var skuBaseId = $addBtn.data('sku-base-id') || $product.data('sku-base-id');
    var skuDataL2 = prodcat.data.getSku(skuBaseId);

    var reaction = prodcat.control.getInventoryStateReaction(skuDataL2);
    var inventoryStateKey = reaction.inventoryStateKey;

    // TODO not a huge fan of the inv status controls being inside a template
    // js. need to remove and cnetralize the inventory management.
    $(document).trigger('product.waitlist.reset', skuDataL2);

    var productCtaText = '';
    if (reaction.cta_ts_key) {
      productCtaText = prodcat.ui.getProductTranslation(reaction.cta_ts_key);
    }

    // If for some reason we are on backorder/preorder without text specific to
    // that state, we use the shoppable text.
    // NOTE: We also rerun for inventoryStateKey shoppable so we can grab the
    // custom add to bag text.
    // TODO: Better support adding a full hash of inventoryStateKey overrides.
    if ((!productCtaText && reaction.shoppable) || inventoryStateKey === 'shoppable') {
      var shoppableStateConfig = prodcat.control.getStateConfig('shoppable');
      productCtaText = prodcat.ui.getProductTranslation(shoppableStateConfig.cta_ts_key);
      if ($addBtn.data('product-cta-shoppable')) {
        // Support custom add to bag text.
        productCtaText = $addBtn.data('product-cta-shoppable');
      }
    }

    // Set cta text.
    $addBtn.html(productCtaText);

    // Based on shoppable or not, disable / show button.
    var addToBagEnabled = reaction.shoppable;

    if (addToBagEnabled) {
      prodcat.ui.enableProductCta($addBtn, $product);
    } else {
      prodcat.ui.disableProductCta($addBtn, $product);
      var hasWaitlist = Drupal.settings.common && Drupal.settings.common.inline_waitlist;
      if (_.includes([2, 3, 7], parseInt(skuDataL2?.INVENTORY_STATUS)) && hasWaitlist) {
        Drupal.behaviors.productWaitlistFormV1?.launch(skuDataL2);
      }
    }

    if (reaction.waitlist) {
      $(document).trigger('product.waitlist.init', skuDataL2);
      $product.addClass('waitlist-active');
    } else {
      $product.removeClass('waitlist-active');
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    $(this).data('sku-base-id', skuBaseId);
    $('.js-add-to-cart, .js-add-to-bag', this).data('sku-base-id', skuBaseId);
    $('.js-add-to-favorites', this).data('sku-base-id', skuBaseId);
    $('.js-add-to-favorites', this).attr('data-sku-base-id', skuBaseId);

    $(this).trigger('product.updateInvStatus');
  });

  // Add All To Cart - update inv stat
  $(document).on('product.updateMultiSkuInvStatus', '.js-product', function () {
    var $addAllToCart = $('.js-add-all-to-cart', this);
    var $skus = $('.js-multi-sku', this);
    if (!$addAllToCart.length) {
      return;
    }
    $addAllToCart.addClass('button--disabled').data('disabled', true);
    _.each($skus, function (sku, index) {
      var skuBaseId = $(sku).data().skuId;
      var productId = $(sku).data().productId;
      var skuDataL2 = prodcat.data.getSku(skuBaseId);
      if (!skuDataL2) {
        return;
      }
      var skuInfo = ".js-multi-sku[data-product-id='" + skuDataL2.PRODUCT_ID + "']";
      var $thisAddAllToCart = $(skuInfo).siblings('.js-add-all-to-cart');
      $(skuInfo).attr({
        'data-inventory-status': skuDataL2.INVENTORY_STATUS,
        'data-is-shoppable': skuDataL2.isShoppable
      });
      if (skuDataL2.isShoppable) {
        $(skuInfo).parent().attr('data-is-shoppable', true);
        $thisAddAllToCart.removeClass('button--disabled').data('disabled', false);
      } else {
        $(skuInfo).parent().attr('data-contains-unshoppable', true);
        // update data attributes
        _.each($thisAddAllToCart, function (btn, index) {
          // data-product-id
          var newProductIdData = $(btn).attr('data-product-id');
          newProductIdData = newProductIdData.replace(productId + ',', '');
          $(btn).attr({
            'data-product-id': newProductIdData
          });
          // data-sku-base-id
          var newSkuBaseIdData = $(btn).attr('data-sku-base-id');
          newSkuBaseIdData = newSkuBaseIdData.replace(skuBaseId + ',', '');
          $(btn).attr({
            'data-sku-base-id': newSkuBaseIdData
          });
        });
      }
    });
  });

  $(document).on('inv_status_data:updated', '.js-product', function () {
    $(this).trigger('product.updateInvStatus');
    // multi skus // add-all-to-cart button
    if ($('.js-add-all-to-cart', this).length) {
      $(this).trigger('product.updateMultiSkuInvStatus');
    }
  });

  $(document).on('inv_status_data:finished', function () {
    $(this).trigger('product.updateInvStatusFinished');
  });

  /*
   * Update the PD Manifest based on L2.
   *
   * Note that we do not use the $product because multiple [data-product-id]
   * can live under .js-product so we could run the pd-manifest update multiple
   * times per $product. Instead use $target and verify that it's
   * pd_manifest-ed.
   */
  $(document).on('inv_status_data:updated.pd_manifest', '.js-product', function (e) {
    var $target = $(e.target);
    if (!$target.data('pd-manifest')) {
      return;
    }
    var updated_pd_manifest = MantlePD.getUpdatedManifestFromEl($target);

    $target.data('pd_manifest', updated_pd_manifest);
    $target.trigger('pd_manifest_updated');
  });

  /*
   * Reorder grid after l2
   */
  $(document).on('inv_status_data:finished.pd_manifest', function () {
    var $grids = $('.js-product-grid.js-mantle-grid:not(.product-grid--carousel)');
    // Allow disable grid L2 for debugging purposes.
    var disableMantleGridL2 = !!JSBoot.cookie.get('disable-grid-l2');
    $grids.each(function (i, obj) {
      var $grid = $(obj);

      if (!$grid.hasClass('mg-grid-loaded')) {
        return;
      }

      MantlePerf.thresholdLog(function () {
        // Trigger the dataset alter. This will update the grid dataset with with
        // updated pdmanifest
        MantleGrid.Dataset.gridDataAlter($grid);
      }, 'productGrid.L2Reorder.gridDataAlter');

      MantlePerf.thresholdLog(function () {
        if (disableMantleGridL2) {
          return;
        }
        // Trigger the resort
        $grid.trigger('mantle-grid:remix', [{ animate: false }]);
      }, 'productGrid.L2Reorder.remix');
    });
  });

  // SPP links open in new window if spp_in_new_window=true in config
  if (Drupal.settings.common && Drupal.settings.common.spp_in_new_window) {
    $(document).on('click', '.js-spp-link', function () {
      $(this).attr('target', '_blank');
    });
  }

  $(document).on('click', '.js-add-to-cart, .js-add-to-bag', function (e) {
    e.preventDefault();
    var $addBtn = $(this);
    if ($addBtn.data('disabled')) {
      return;
    }
    var skuBaseId = $addBtn.data('sku-base-id');
    if (!skuBaseId || skuBaseId.length < 1) {
      return;
    }

    // Trigger the button toggle event to show loading message until all this code and RPC call are complete.
    $(document).trigger('addToCart.toggle', [$addBtn]);

    // Account for adding multiple skus to bag
    var args = {};
    args.$addBtn = $addBtn;
    skuBaseId = String(skuBaseId);
    if (skuBaseId.indexOf(',') >= 0) {
      // clean the string
      skuBaseId = skuBaseId.replace(/(\s|\r\n|\n|\r)/gm, '');
      args.skuBaseId = skuBaseId.split(',');
      if (skuBaseId.slice(-1) === ',') {
        args.skuBaseId.pop();
      }
    } else {
      args.skuBaseId = skuBaseId;
    }

    var quantity = $addBtn.data('qty');
    if (!!quantity) {
      args.quantity = quantity;
      args.INCREMENT = quantity;
    } else {
      args.INCREMENT = 1;
    }

    // Check if button is configured for kits.
    var kitType = $addBtn.data('kit-type');
    var kitSubtype = $addBtn.data('kit-subtype');
    var kitCat = $addBtn.data('kit-cat') || '';
    var isKit = kitType === 'UKIT';
    var kitName = $addBtn.data('kit-name');
    if (!!kitName) {
      kitName = kitName + ' ';
    } else {
      kitName = Drupal.settings.common.bundle_name;
    }

    if (isKit) {
      var collectionName = kitName + Math.floor(Math.random() * 10000);
      args.COLLECTION_TYPE = kitType;
      args.CAT_BASE_ID = kitCat;
      args.COLLECTION_SUBTYPE = kitSubtype;
      args.COLLECTION_NAME = collectionName;
      args.SKU_BASE_ID = args.skuBaseId;
      args.action = 'add,edit,create_kit';
      args._SUBMIT = 'alter_collection';
      args.HAS_MULTIPLE_SKUS = 1;
      if ($.cookie('csrftoken')) {
        args._TOKEN = $.cookie('csrftoken');
      }

      // NOTE: Kit needs its own version of RPC calls so we return to not call
      // base addToCart().
      prodcat.ui.addToCartKit(args);
      return;
    }

    // Replenishment updates when sku is refillable and enable_replenishment=true in config.
    //  (currently applicable only when adding one item at a time)
    if (
      _.isString(args.skuBaseId) &&
      Drupal.settings.common &&
      Drupal.settings.common.has_replenishment
    ) {
      var skuDataL2 = prodcat.data.getSku(args.skuBaseId);
      if (skuDataL2.REFILLABLE) {
        var frequency = $addBtn.attr('data-replenishment');
        if (!!frequency) {
          args.REPLENISHMENT_FREQ = frequency;
        } else {
          args.REPLENISHMENT_FREQ = 0;
        }
        args.action = 'add';
        args.itemType = 'replenishment';
        args.add_to_cart = 1;
      }
    }

    prodcat.ui.addToCart(args);
  });

  /*
   ***********************
   * Add-to-favorites button
   ***********************
   */

  /*
   * General tooltipster flow for favorites.
   * 1. init tooltipster with default settings
   * 2. On favorites click handler, we disable and hide tooltipster, then
   * change the content to some variations of success/removed anon/logged in
   * 3. Then prodcat.ui.addToFavorites gets called which makes an RPC call.
   * 4. From the callback to that call, we end up trigger product.favorites
   * which ends up showing the tooltipster with the content we staged in step
   * #2
   *
   * TODO: this was taken from smashbox but honestly shoudl be refactored.
   * 1. grab from a global translation set value so we're not spamming the
   * labels
   * 2. don't set content until we actual show tooltipster.
   */
  var tooltipsterDefaultSettings = {
    animation: 'fade',
    arrow: false,
    contentAsHTML: true,
    interactive: true,
    multiple: true,
    onlyOne: true,
    position: 'left',
    restoration: 'none',
    speed: 500,
    theme: 'tooltipster-toofaced',
    timer: 3000,
    trigger: 'click',
    updateAnimation: false
  };

  $(document).on('click', '.js-add-to-favorites', function (e) {
    e.preventDefault();
    var $this = $(this);
    var skuBaseId = $this.data('sku-base-id');

    var removed_content = $this.data('tooltip-removed');
    if (!removed_content) {
      removed_content = prodcat.ui.getProductTranslation('product_favorites_removed');
    }
    var success_content = $this.data('tooltip-success');
    var success_content_anon = $this.data('tooltip-success-anon');
    if (!success_content) {
      success_content = prodcat.ui.getProductTranslation('product_favorites_success');
    }

    // TODO not sure there is a better way?
    if ($('body').hasClass('elc-user-state-anonymous')) {
      if (!success_content_anon) {
        success_content_anon = prodcat.ui.getProductTranslation('product_favorites_success_anon');
      }
      // if we have an anon version. show that.
      if (success_content_anon) {
        success_content = success_content_anon;
      }
    }

    $this.tooltipster(tooltipsterDefaultSettings);
    $this.tooltipster('disable').tooltipster('hide', function () {
      if ($this.hasClass('favorite-added')) {
        $this.tooltipster('content', removed_content);
        prodcat.ui.addToFavorites({ skuBaseId: skuBaseId, $el: $this, action: 'delete' });
      } else {
        $this.tooltipster('content', success_content);
        prodcat.ui.addToFavorites({ skuBaseId: skuBaseId, $el: $this, action: 'add' });
      }
    });
  });

  /*
   ***********************
   * display text fields (price, shade name, etc)
   ***********************
   */
  $(document).on(
    'product.skuSelect product.skuDisplay',
    '.js-product',
    function (e, skuBaseId, disableRouteUpdate) {
      $(this).trigger('product.updateText', [skuBaseId]);
      $(this).trigger('product.updateImage', [skuBaseId]);
      if (!disableRouteUpdate) {
        $(this).trigger('product.updateRoute', [skuBaseId]);
      }
    }
  );

  /*
   * This will rerender the product_sku_price template. This is to handle more
   * complicated pricing displays like sales.
   */
  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    // Price update logic
    var sku = prodcat.data.getSku(skuBaseId);

    if (sku) {
      $(() => {
        // Need to wrap sku inside of defaultSku because thats where the template reads
        var content = site.template.get({
          name: 'product_sku_price',
          data: { defaultSku: sku }
        });

        $('.js-product-sku-price', this).html($(content).html());
      });
    }
  });

  $(document).on('product.updateText', '.js-product', function (e, skuBaseId) {
    var reaction = { skuBaseId: skuBaseId };
    var skuData = prodcat.data.getSku(skuBaseId);
    if (_.isEmpty(skuData)) {
      console.warn('No prodcat data for ' + skuBaseId);
      return;
    }
    var product_id = skuData['PRODUCT_ID'];

    var $productEl = $(this);
    prodcat.ui.renderAllReactions($productEl, reaction);
    var $reaction_containers = $(
      '.js-product-reaction-container[reaction-product-id="' + product_id + '"]'
    );
    prodcat.ui.renderAllReactions($reaction_containers, reaction);
  });

  $(document).on('product.updateImage', '.js-product', function (e, skuBaseId) {
    var $product = $(this);
    var skuData = prodcat.data.getSku(skuBaseId);
    var prodId = !!skuData ? skuData.PRODUCT_ID : $product.attr('data-product-id');
    var prodData = prodcat.data.getProduct(prodId);

    if (_.isEmpty(skuData) || _.isEmpty(skuData.IMAGE_M[0]) || _.isEmpty(prodData)) {
      console.warn('No prodcat data for ' + skuBaseId);
      return;
    }

    if (!(prodData.sku_count > 1)) {
      return;
    }

    $product.find('.js-product-brief-image').attr({
      src: skuData.IMAGE_M[0],
      'data-src': skuData.IMAGE_M[0]
    });
  });

  // @TODO: This probably needs to be smarter, giving a specific message to the user,
  //        if this is something they've already favorited. Also, the already favorited
  //        products should have their heart icon filled in on page load, somehow (currently,
  //        they're not).
  $(document).on('addToWishlist.success addToWishlist.exists', function (event, cr, $el) {
    // Set icon to heart--selected, in the wake of a successful favoriting (even if it was already a favorite)
    // $el.find('.icon').removeClass('icon--heart').addClass('icon--heart--selected');
    $el.find('.js-add-to-favorites-label').hide();
    $el.find('.js-add-to-favorites-label-success').show();
  });

  // @TODO: This probably needs to be nicer, not just dumping errors to an alert
  // box.
  $(document).on('addToCart.failure addToWishlist.failure', function (event, errorObjectsArray) {
    // Escape any html in the alert box.
    var prodAddedMsg = $('<div/>').html(errorObjectsArray[0].text).text();
    alert(prodAddedMsg);
  });

  /**
   * Toggles the visibility of an add-to button, and its sibling loading message
   */
  $(document).on('addToCart.toggle', function (event, $addBtn) {
    if (!$addBtn || $addBtn.length < 1) {
      return;
    }

    $addBtn.toggleClass('hidden');

    var loadingDiv = $addBtn.siblings('.js-loading-message').eq(0);

    if (loadingDiv && loadingDiv.length > 0) {
      // Hide Quick Shop overlay after Add to Bag
      if (!$addBtn.hasClass('hidden')) {
        $('.js-quickshop-close-inline').trigger('click');
      }

      loadingDiv.toggleClass('hidden');
    }
  });

  $(document).on('product.updateRoute', '.js-product', function (e, skuBaseId) {
    var $product = $(this);
    var skuData = prodcat.data.getSku(skuBaseId);
    var prodId = !!skuData ? skuData.PRODUCT_ID : $product.attr('data-product-id');
    var prodData = prodcat.data.getProduct(prodId);
    var isProdSized = (!!prodData && prodData.sized) || $product.attr('data-product-sized') ? 1 : 0;
    var routePrefix = !isProdSized ? '#/shade/' : '#/sku/';

    var routeString;
    if (skuData) {
      if (isProdSized) {
        routeString = _.result(skuData, 'SKU_BASE_ID');
      } else {
        routeString = _.result(skuData, 'SHADENAME');
      }
    } else {
      routeString = $product.attr('data-product-route') || '';
    }

    // include reserved characters missing from encodeURIComponent()
    function _fixedEncodeURIComponent(str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    }

    if (!!routeString) {
      var $sppLinks = $('.js-spp-link', $product);
      prodcat.ui.routeNoSpaces = _fixedEncodeURIComponent(
        routeString.toString().split(' ').join('_')
      );

      $sppLinks.each(function () {
        var url = prodData ? prodData.url : $(this).attr('href');
        $(this).attr('href', url + routePrefix + prodcat.ui.routeNoSpaces);
      });

      if ($product.hasClass('product-full')) {
        var routeWithQueryString = window.location.hash;
        if (routeWithQueryString.indexOf('?') >= 0) {
          prodcat.ui.routeNoSpaces += '?' + routeWithQueryString.split('?')[1];
        }
        history.replaceState({}, routeString, routePrefix + prodcat.ui.routeNoSpaces);
      }
    }
  });

  /**
   * Wire up behavior on page-load according to js- classnames.
   */
  prodcat.ui.shadeNameNoSpaces = '';
  prodcat.ui.routeNoSpaces = '';
  Drupal.behaviors.prodcatUiInit = {
    attach: function () {
      MantlePerf.thresholdLog(function () {
        $(document).trigger('products:init-start');
      }, 'products:init-start');

      MantlePerf.thresholdLog(function () {
        $('.js-product', document).trigger('product:init');
      }, 'js-product:product:init');

      MantlePerf.thresholdLog(function () {
        $(document).trigger('products:init-finish');
      }, 'products:init-finish');
    }
  };

  prodcat.ui.renderAllReactions = function ($dom, reactions) {
    if (!reactions) {
      return;
    }
    if (reactions.product_id) {
      prodcat.ui.renderProductReactions($dom, reactions.product_id);
    }
    if (reactions.skuBaseId) {
      prodcat.ui.renderSkuReactions($dom, reactions.skuBaseId);
    }
  };

  prodcat.ui.renderProductReactions = function ($dom, product_id) {
    var textFields = [{ selector: '.js-product-name', field: 'PROD_RGN_NAME' }];
    var prodData = prodcat.data.getProduct(product_id);
    prodcat.ui._renderReactions($dom, textFields, prodData);
  };

  prodcat.ui.renderSkuReactions = function ($dom, skuBaseId) {
    var textFields = [
      { selector: '.js-product-size', field: 'PRODUCT_SIZE' },
      { selector: '.js-product-price', field: 'formattedPrice' },
      { selector: '.js-sku-shade-name', field: 'SHADENAME' },
      { selector: '.js-sku-shade-description', field: 'SHADE_DESCRIPTION' },
      { selector: '.js-sku-shade-ingredients', field: 'ILN_LISTING, ILN_LISTING_1' }
    ];
    var skuData = prodcat.data.getSku(skuBaseId);
    if (!skuData) {
      return;
    }
    var prodData = prodcat.data.getProduct(skuData['PRODUCT_ID']);
    prodcat.ui._renderReactions($dom, textFields, skuData);

    if (prodData['shaded']) {
      this.renderSkuSmooshReaction($dom, skuData, prodData);
    }
  };

  prodcat.ui.renderSkuSmooshReaction = function ($dom, skuData, prodData) {
    // Shade Swatch
    var $shadeSmooshTriggers = $('.js-sku-shade-smoosh-reaction', $dom);
    var hexValue = skuData['HEX_VALUE_STRING'];
    var smooshString = skuData['SMOOSH_PATH_STRING'];
    var cssObj = {};

    if (!_.isEmpty(hexValue)) {
      cssObj['background-color'] = hexValue;
    } else {
      cssObj['background-image'] = 'url(' + smooshString + ')';
    }
    $shadeSmooshTriggers.css(cssObj);
  };

  prodcat.ui._renderReactions = function ($dom, textFields, data) {
    if (!data) {
      return;
    }
    _.each(textFields, function (el) {
      var $el = $(el.selector, $dom);
      if ($el.length < 1) {
        return;
      }
      $el.html(data[el.field]);
    });
  };

  /*
   * Process the LARGE_IMAGE/LARGE_ALT_IMAGES for a sku and wraps them in an
   * akamai ImageManager url if possible.
   *
   * Note that the URL returned is currently an absolute URL since lower envs
   * do not have ImageManager and need to point to a prod env to test.
   */
  prodcat.ui.generateLargeImagePackWithWidth = function (skuData, width) {
    /*
     * This is used to normalize the quickshop image data and also allow
     * inserting Akamai Image Manager imwidth
     */
    var productImages = [];

    if (skuData.LARGE_IMAGE.length > 0) {
      _.each(skuData.LARGE_IMAGE, function (image) {
        image = MantleFEImages.processImageWidth(image, width);
        productImages.push(image);
      });
    }

    if (skuData.LARGE_ALT_IMAGES.length > 0) {
      _.each(skuData.LARGE_ALT_IMAGES, function (image) {
        image = MantleFEImages.processImageWidth(image, width);
        productImages.push(image);
      });
    }

    var imagePack = {
      images: productImages
    };

    if (productImages.length > 1) {
      imagePack.has_multiple = true;
    }

    return imagePack;
  };
})(jQuery, (window.generic = window.generic || {}));
