/*global videojs */
var MantleVideo = MantleVideo || {};

(function($) {
  /*
   * Video Element Manager.
   *
   * This handles *only* the video element which includes:
   * 1. videojs video
   * 2. Landing images
   * 3. play, stop, etc
   * 4. Triggering cuepoints that are provided externally.
   *
   * Note that the cuepoint handling is only within this class due to the use
   * of videojs-markers. So this class only handles providing the cuepoints to
   * the videojs plugin. In the future, we should probably remove that dep and
   * just respond timeline events in MantleVideoPlayer.
   */
  function ELCVideoJS($videoElement) {
    // Guard against re-initing incorrectly.
    if ($videoElement.data('video-manager')) {
      throw new Error('Attempted to initialize video element twice');
    }

    this.$videoElement = $videoElement;
    this.$landing = $videoElement.find('.js-videojs-landing');
    this.hasPosterMediaFile = !!(this.$landing && this.$landing.data('posterImage'));
    this.$landingImage = $videoElement.find('.js-videojs-landing__image');
    this.currentMarker = {};
    this._player = null;
    this.inited = false;
    this.markers = null;
    this.markers_inited = false;

    var self = this;
    var dataSetup = this.$videoElement.find('.video-js').data('setup');
    if (dataSetup?.aspectRatio) {
      var aspectRatio = dataSetup.aspectRatio.replace(/:/g, '-');
      $videoElement.addClass(`player-aspect-ratio-${aspectRatio}`);
    }
    this.enableAutoplay = dataSetup && dataSetup.autoplay && dataSetup.muted ? true : false;
    this.providerHtmlFive = dataSetup && dataSetup.providerHtmlFive;
    this.enableLoop = dataSetup && dataSetup.loop;
    if (this.enableAutoplay) {
      this.init();
    }

    // Need this setup. Store markers for later use.
    // we configure markers when videojs player is made
    $videoElement.on('video-set-markers', function(e, markers) {
      // depending on ordering. this might get called before markers
      // are initialized. so just resetting them won't work cuz it hasn't
      // been initied yet
      self.markers = markers;
      if (self.inited) {
        self.getPlayer().markers.reset(self.markers);
      }
    });

    // Since the tick checking is currently being handled by video js, allow us
    // to inject our own active markers. Once added, videojs will handle
    // notifiying us when they are expired
    $videoElement.on('video-add-current-marker', function(e, marker) {
      self.add_current_marker(marker);
    });

    // Handle any clicks to the landing div as an intent to play.
    $videoElement.on('click', '.js-videojs-landing', function(e) {
      $videoElement.trigger('start-video');
    });
    

    $videoElement.data('video-manager', this);
  }

  /*
   * Clone the videoElement with a new ELCVideoJS controller attached.
   * This is primarily used for overlays.
   */
  ELCVideoJS.prototype.clone = function() {
    var $newVideoElement = this.$videoElement.clone();
    var elcVideo = new ELCVideoJS($newVideoElement);
    return $newVideoElement;
  };

  ELCVideoJS.prototype.getPlayer = function() {
    if (this._player == null) {
      var video = this.$videoElement.find('elc-video-js, video-js, video').get(0);
      var videoSource = this.$videoElement.find('video').get(0);
      $(video).addClass('video-js');
      this._player = videojs(video);
      if (typeof videoSource !== 'undefined') {
        videoSource.addEventListener('touchend', (event) => {
          if(this._player.paused()) {
            this._player.play();
          } else {
            this._player.pause();
          }
        });
      }
    }
    return this._player;
  };

  ELCVideoJS.prototype.init = function() {
    if (this.inited) {
      return;
    }
    this.inited = true;

    this._init_markers();
    this._init_player_events();
  };

  /*
   * Conceptually there is the <videojs> play and the ELCVideoJS play. Renamed
   * ELCVideoJS.play to startVideo to help denote the difference.
   *
   * startVideo handles making sure the <videojs> element is initialized and
   * ready to go.
   */
  ELCVideoJS.prototype.startVideo = function() {
    if (!this.$videoElement.hasClass('videojs-video--loading')) {
      this.init()
      this.$videoElement.addClass('videojs-video--loading');
    }

    var player = this.getPlayer();
    const readyVideoElement = () => {
      let playerReady = player.isReady_;
      if (playerReady) {
        this.$videoElement.trigger('video-ready', [player]);
        player.play();
        return;
      } else {
        setTimeout(function(){
          readyVideoElement();
        }, 250);
      }
    }
    readyVideoElement();
    player.on('ready', function() {
      // timeout required for techGet on videoJS to be ready as well.
      setTimeout(function() {
        player.play();
      }, 1000);
    });

  };

  ELCVideoJS.prototype.add_current_marker = function(marker) {
    this.currentMarker[marker['mantle_key']] = marker;
  };

  ELCVideoJS.prototype._init_markers = function() {
    var $videoElement = this.$videoElement;
    var player = this.getPlayer();

    var self = this;

    var options = {
      markerStyle: {
        'background-color': '',
        'width': '',
        'border-radius': '',
      },
      markerTip: {
        display: false,
      },
      breakOverlay: {
        display: false,
      },
      onMarkerMouseout: function(marker) {
        $videoElement.trigger('video-marker-mouseout', [marker]);
      },
      onMarkerMouseover: function(marker) {
        $videoElement.trigger('video-marker-mouseover', [marker]);
      },
      onMarkerReached: function(marker, i) {
        self.add_current_marker(marker);
        $videoElement.trigger('video-marker-reached', [marker]);
      },
      onMarkerClick: function(marker, i) {
        $videoElement.trigger('video-marker-click', [marker]);
        return false;
      },
    };

    player.markers(options);

    player.on('timeupdate', function(e) {
      if (!self.markers || self.markers.length === 0) {
        return;
      }
      $.each(self.markers, function (key, marker) {
        if (!marker.time_duration) {
          return;
        }
        var currentTime = player.currentTime();
        var initTime = marker.time;
        var exitTime = initTime + marker.time_duration;

        if (currentTime < initTime || currentTime >= exitTime) {
          $videoElement.trigger('video-marker-exited', [marker]);
          delete self.markers[marker['mantle_key']];
        } else {
          $videoElement.trigger('video-marker-reached', [marker]);
        }
      });
    });
  };

  ELCVideoJS.prototype._init_player_events = function() {
    var $videoElement = this.$videoElement;
    var player = this.getPlayer();
    var self = this;

    $videoElement.on('video-pause', function(e) {
      player.pause();
    });

    $videoElement.on('video-play', function(e) {
      player.play();
    });

    player.on('pause', function() {
      $videoElement.addClass('video-paused');
      $videoElement.trigger('video-paused');
      self.$landing.show();
    });

    // There is probably a better way to detect that we're clicked to play.
    player.on('waiting', function() {
      $videoElement.trigger('video-waiting');
      if (player.isReady_ && !player.hasStarted_) {
        setTimeout(function() {
          $videoElement.trigger('video-play');
        }, 1000);
      }
    });

    player.bigPlayButton.on('click', function(e) {
      $videoElement.trigger('start-video');
    });
    
    player.on('play', function() {
      $videoElement.removeClass('videojs-video--loading');
      
      // when video ends. we reset currentTime which trigger the play event.
      // this is obviously not a real play. So we keep track of the rewind
      // state via a class and don't do things like hide landing unless we're
      // on a real play event.
      if (!$videoElement.hasClass('video-end-process')) {
        self.$landing.hide();
        self.$landingImage.hide();
        player.posterImage.hide();
        player.controlBar.show();
        player.bigPlayButton.hide();
        $videoElement.trigger('video-started');
      }

      if (self.markers_inited) {
        return;
      }

      // youtube doesn't have duraiton until playing.
      // so we force an update on play
      if (self.markers) {
        self.markers_inited = true;
        player.markers.reset(self.markers);
      }
    });

    player.on('ended', function () {
      // wait a sec to make sure that the user hasn't decided to play the video again.
      setTimeout(function () {
        if (player.isFullscreen_ && $videoElement.hasClass('vjs-user-inactive')) {
          player.exitFullscreen();
        }
      }, 1000);
    });

    player.on('requestFullscreen', function(){
      $videoElement.addClass('video-block--fullscreen');
      closeOtherFullscreens();
    });

    player.on('exitFullscreen', function(){
      $videoElement.removeClass('video-block--fullscreen');
    });

    player.on('fullscreenchange', function(){
      $videoElement.toggleClass('video-block--fullscreen');
    });

    const closeOtherFullscreens = (el) => {
      let thisVideoBlock = el.closest('.js-video-block');
      let otherVideoBlocks = document.querySelectorAll('.js-video-block');
      otherVideoBlocks.forEach((block) => {
        if (block !== thisVideoBlock){
          let thisPlayer = block.getPlayer();
          thisPlayer.exitFullscreen();
        }
      })
    }

    /*
     * Handle the end of video playback. We reset back to the original state so
     * that we don't see the youtube related videos and such. This should
     * probably be configruable as to what end behavior we want.
     */
    player.on('ended', function() {
      if (self.enableLoop) {
        return;
      }

      $videoElement.trigger('video-ended');
      $videoElement.addClass('video-end-process');
      player.currentTime(0); // this will trigger play again
      if (!self.providerHtmlFive) {
        // @todo: Clean up this code or remove the following lines after all brands make the upgrade and use the new fields
        player.posterImage.show();
        $(player.posterImage.contentEl()).show();
        $(player.bigPlayButton.contentEl()).show();
      }

      if (self.hasPosterMediaFile) {
        self.$landing.show();
        self.$landingImage.show();
      }
      player.bigPlayButton.show();
      player.controlBar.hide();

      // setting currentTime does some async stuff
      // so we set a class that forces poster.
      // then we remove it once we are setup
      window.setTimeout(function() {
        $videoElement.removeClass('video-end-process');
        $videoElement.trigger('video-ended-final');
      }, 300);
    });
  };

  MantleVideo.ELCVideoJS = ELCVideoJS;
})(jQuery);

/*
 * Drupal specific integrations.
 */
(function($, Drupal) {
  // Top level listener. Other components like MantleVideoPlayer can intercept
  // this event and have specialized start behavior. This top level is just a
  // fallback to make the ELCVideoJS work independent of a containing
  // component.
  $(document).on('start-video', '.js-videojs-video', function(e) {
    var $videoElement = $(this);
    var elcVideo = $videoElement.data('video-manager');
    elcVideo.startVideo();
  });

  Drupal.behaviors.videoJSElement = {
    attachReal: function(context, force) {
      force = !!force;

      var $videoElements = $('.js-videojs-video', context);

      $videoElements.each(function(i, obj) {
        var $videoElement = $(obj);
        // already inited. bye
        if ($videoElement.data('video-element-initialized')) {
          return;
        }

        // we have a parent template that wants to control this.
        var $videoControllers = $videoElement.closest('[data-video-element-controller="1"]');
        if ($videoControllers.length > 0 && !force) {
          return;
        }

        var elcVideo = new MantleVideo.ELCVideoJS($videoElement);

        $videoElement.data('video-element-initialized', 1);
      });
    },

    attach: function(context, settings) {
      var mod = this;
      mod.attachReal(context, false);
    }
  };
})(jQuery, Drupal);