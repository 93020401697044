/*
 * Utils to detect things like clicks being within a box. elemetns being in a
 * box. etc.
 *
 * Not sure if this belongs in JSBoot since it has no jquery deps
 */
var MantleHitbox = (function(MantleHitbox) {
  /*
   * From an elem, generate a hitbox set of coordinates. Essentially we want
   * detect clicks that are outside of the $elem. So we need to know what
   * coordinates to respond to since we're doing this from a document level.
   */
  MantleHitbox.generate_hitbox = function(elem, x_cushion, y_cushion) {
    if (!elem) {
      return;
    }

    if (!x_cushion) {
      x_cushion = 0;
    }
    if (!y_cushion) {
      y_cushion = 0;
    }

    if (JSBoot.isJQuery(elem)) {
      elem = elem[0];
    }

    var rect = elem.getBoundingClientRect();

    var width = elem.offsetWidth;
    var height = elem.offsetHeight;

    var left = rect.left;
    var right = left + width;
    var top = rect.top;
    var bottom = top + height;

    var hitboxLeft = left - x_cushion;
    var hitboxRight = right + x_cushion;
    var hitboxTop = top - y_cushion;
    var hitboxBottom = bottom + y_cushion;

    var hitbox = {
      'left': hitboxLeft,
      'right': hitboxRight,
      'top': hitboxTop,
      'bottom': hitboxBottom,
      // added dimensions for convenience
      'width': width,
      'height': height,
    };
    return hitbox;
  };

  /*
   * Taking too hitboxes. A box that covers both boxes.
   */
  MantleHitbox.merge_max = function(a, b) {
    var hitboxLeft = Math.min(a.left, b.left);
    var hitboxRight = Math.max(a.right, b.right);
    var hitboxTop = Math.min(a.top, b.top);
    var hitboxBottom = Math.max(a.bottom, b.bottom);

    var hitbox = {
      'left': hitboxLeft,
      'right': hitboxRight,
      'top': hitboxTop,
      'bottom': hitboxBottom,
    };
    return hitbox;
  };

  MantleHitbox.get_event_coords = function(e) {
    var touch = null;

    if (e.originalEvent && e.originalEvent.changedTouches) {
      touch = e.originalEvent.changedTouches[0];
    }

    // We check existence of touch because pageX/Y might be 0, which is valid
    // but fails boolean check.
    var pageX = touch === null ? e.pageX : touch.pageX;
    var pageY = touch === null ? e.pageY : touch.pageY;
    var coords =  {'x': pageX, 'y': pageY};
    return coords;
  }

  /*
   * Check wheter a x/y coord 
   */
  MantleHitbox.coords_within_hitbox = function(hitbox, coords) {
    // check hitbox.
    if (coords.x < hitbox.left || coords.x  > hitbox.right) {
      return false;
    }

    if (coords.y < hitbox.top || coords.y > hitbox.bottom) {
      return false;
    }
    return true;
  }

  return MantleHitbox;
})(MantleHitbox || {});
