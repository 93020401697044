var MantleTouch = (function(MantleTouch, $) {
  MantleTouch.settings = {
    threshold: 150, //required min distance traveled to be considered swipe
    restraint: 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime: 500, // maximum time allowed to travel that distance
    limitTarget: true // will only trigger if the event target is the exact one the ontouch was called on
  };
  MantleTouch.ontouch = function(el, callback) {
    if (el instanceof jQuery) {
      el = el[0];
    }
    var touchsurface = el;
    var dir = '';
    var swipeType = '';
    var startX = 0;
    var startY = 0;
    var distX = 0;
    var distY = 0;
    var startTime;
    var handletouch = callback || function(evt, dir, phase, swipetype, distance) {};

    touchsurface.addEventListener('touchstart', function(e) {
      if (MantleTouch.settings.limitTarget && MantleTouch.isValidElement(el, e.target) === false) {
        return;
      }
      var touchobj = e.changedTouches[0];
      dir = 'none';
      swipeType = 'none';
      startX = touchobj.pageX;
      startY = touchobj.pageY;
      startTime = new Date().getTime(); // record time when finger first makes contact with surface
      handletouch(e, 'none', 'start', swipeType, 0); // fire callback function with params dir="none", phase="start", swipetype="none" etc
    }, {capture: false, passive: true});

    touchsurface.addEventListener('touchmove', function(e) {
      if (MantleTouch.settings.limitTarget && MantleTouch.isValidElement(el, e.target) === false) {
        return;
      }
      var touchobj = e.changedTouches[0];
      distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
      distY = touchobj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
      if (Math.abs(distX) > Math.abs(distY)) { // if distance traveled horizontally is greater than vertically, consider this a horizontal movement
        dir = distX < 0 ? 'left' : 'right';
        handletouch(e, dir, 'move', swipeType, distX); // fire callback function with params dir="left|right", phase="move", swipetype="none" etc
      } else { // else consider this a vertical movement
        dir = distY < 0 ? 'up' : 'down';
        handletouch(e, dir, 'move', swipeType, distY); // fire callback function with params dir="up|down", phase="move", swipetype="none" etc
      }
    }, {capture: false, passive: true});

    touchsurface.addEventListener('touchend', function(e) {
      if (MantleTouch.settings.limitTarget && MantleTouch.isValidElement(el, e.target) === false) {
        return;
      }
      if (!MantleTouch.isValidSwipe(startTime, distX, distY)) {
        return;
      }
      swipeType = dir; // set swipeType to either "left" or "right"
      // Fire callback function with params dir="left|right|up|down", phase="end", swipetype=dir etc:
      handletouch(e, dir, 'end', swipeType, dir === 'left' || dir === 'right' ? distX : distY);
    }, {capture: false, passive: true});
  };

  MantleTouch.isValidSwipe = function(startTime, distX, distY) {
    var isValidElapsedTime = new Date().getTime() - startTime <= MantleTouch.settings.allowedTime;
    var isValidHorizontalSwipe = Math.abs(distX) >= MantleTouch.settings.threshold && Math.abs(distY) <= MantleTouch.settings.restraint;
    var isValidVerticalSwipe = Math.abs(distY) >= MantleTouch.settings.threshold && Math.abs(distX) <= MantleTouch.settings.restraint;
    return isValidElapsedTime || isValidHorizontalSwipe || isValidVerticalSwipe;
  };

  MantleTouch.isValidElement = function(elem, target) {
    if ($(elem).attr('class') === $(target).attr('class')) {
      return true;
    }
    return false;
  };
  return MantleTouch;
})(MantleTouch || {}, jQuery);
