var MantleUI = MantleUI || {};
var MantleCarousel = MantleCarousel || {};

(function($) {
  /*
   * RTWidget for Carousels.
   */

  var MantleCarouselRTWidget = MantleUI.MantleRTWidget.createSubclass(
    function(overrides, widgetKey, element) {
      MantleUI.MantleRTWidget.call(this, overrides, widgetKey, element);

      var options = this.options;
      this.carouselSettings = options.carouselSettings || $(element).data('carousel-settings');
      this.slideSelector = this.carouselSettings.slide;
      this.carouselSelectionMode = options.carouselSelectionMode;
    }
  );

  MantleCarouselRTWidget.prototype.initialize = function(options) {
    var self = this;
    var $carousel = $(this.element);
    var carouselSettings = this.carouselSettings;
    var initialSlide = options.initialSlide || 0;

    carouselSettings.initialSlide = initialSlide;
    _.forEach(carouselSettings.responsive, function(info) {
      info.settings.initialSlide = initialSlide;
    });

    if ($carousel.length > 0 && this.initMode !== 'onSelection') {
      // Init this carousel with our settings
      MantleCarousel.initializeCarousel($carousel, carouselSettings);
    }

    $carousel.on('beforeChange.RTD', function(event, slick, currentSlide, targetSlide) {
      $carousel.addClass('carousel-sliding');
      // As of right now. We check just check the tickNum vs targetSlide.
      // In the future not sure if we need to keep track of which widget truly
      // was the originator since beforeChange gets called every time carousel
      // chagnes. Whether it be from mouse or sync.
      var currentTick = self.getCurrentTick();
      if (targetSlide === currentTick) {
        return;
      }
      if (currentSlide === targetSlide) {
        return;
      }
      self.tickUpdate(targetSlide);
    });

    $carousel.on('afterChange.RTD', function(event, slick, currentSlide) {
      $carousel.removeClass('carousel-sliding');

      // update input after first interaction
      if (self.carouselSelectionMode === 'selected') {
        var currentTick = self.getCurrentTick();
        self.setSelectedTick(currentTick);
      }
    });

    /*
     * Handle slide click. Sometimes we want these to count as a selection.
     */
    $carousel.on('click', '.slick-slide', function() {
      var $slide = $(this);
      var slideId = MantleCarousel.get_mantle_id(null, $slide);
      $carousel.trigger('slideClicked', slideId);

      // Register a click on the selectedSlide *after* it has moved.
      if ($slide.hasClass('slide-selected-finished')) {
        $carousel.trigger('selectedSlideClickedAfterMove', slideId);
      }
    });

    $carousel.on('selectedSlideClickedAfterMove', function(e, tickNum) {
      self.onSelectedSlideClickedAfterMove(e, tickNum);
    });

    $carousel.on('slideClicked', function(e, tickNum) {
      self.onSlideClicked(e, tickNum);
    });
  };

  /*
   * initialize the carousel if our initMode is onSelection.
   */
  MantleCarouselRTWidget.prototype.handleSelectedTick = function() {
    var $carousel = this.element;
    if (this.initMode === 'onSelection' && this.getSelectedTick() === null) {
      return;
    }

    if (!MantleCarousel.isCarousel($carousel)) {
      MantleCarousel.initializeCarousel($carousel, this.carouselSettings);
    }
  };

  MantleCarouselRTWidget.prototype.handleTickUpdate = function(newTick) {
    var $carousel = this.element;

    var currentSlide = MantleCarousel.currentSlide($carousel);
    if (currentSlide === newTick) {
      return;
    }
    MantleCarousel.slideTo($carousel, newTick);
  };

  /*
   * Handle carousel click after selected slide has finished moving. So in
   * essense this requires two clicks.
   *
   * SelectedSlide refers to the Carousel's concept of selected. Doesn't mean
   * the director is selected as well.
   *
   * NOTE: This depends on startedSelect and selectionMode. Sometimes those
   * configs will override slide click event handlers
   */
  MantleCarouselRTWidget.prototype.onSelectedSlideClickedAfterMove = function(e, tickNum) {
    // Make sure we dont register clicks on slide as they move
    if (this.carouselSelectionMode === 'selectedClick') {
      this.setSelectedTick(tickNum);
      console.log('onSlideClicked', tickNum);
      return;
    }
  };

  /*
   * Allow clicking the slide to set selection.
   *
   * NOTE: This depends on startedSelect and selectionMode. Sometimes those
   * configs will override slide click event handlers
   */
  MantleCarouselRTWidget.prototype.onSlideClicked = function(e, tickNum) {
    // update input after slide has been selected THEN clicked.
    if (this.carouselSelectionMode === 'selected') {
      // clicky clicky
      this.setSelectedTick(tickNum);
      console.log('onSlideClicked', tickNum);
    }
  };

  MantleUI.MantleCarouselRTWidget = MantleCarouselRTWidget;
}(jQuery));
