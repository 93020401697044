var generic = generic || {};
var site = site || {};
var prodcat = prodcat || {};

(function($) {
  'use strict';

  var turnRelativeToAbsolute = function(urlToFix) {
    // If the first URL is a backslash, the URL is relative and Pinterest won't pull it. We need to make this a full URL.
    if (!!urlToFix && urlToFix.substr(0, 1) === '/') {
      // Split the URL by backslashes
      var urlArray = location.href.split('/');
      // Find the web protocol i.e. http, https
      var protocol = urlArray[0];
      // We know there is always 2 backslashes before the website URL
      // Put the protocol and the base url together
      var websiteUrl = protocol + '//' + urlArray[2];
      // Now add the base url to the relative image url to make it absolute
      urlToFix = websiteUrl + urlToFix;
    }
    return urlToFix;
  };

  var setupShareLinks = function(refresh) {
    // setup share links
    var $shareLinks = $('.js-share');
    /* eslint-disable-next-line complexity */
    $shareLinks.each(function() {
      var $link = $(this);
      //var $ogImage = $('meta[property="og:image"]');
      //var $ogDescription = $('meta[property="og:description"]').attr('content');

      var $container = $link.closest('.js-product-social');
      var $quickshop = $link.closest('.js-quickshop');
      var $spp = $link.closest('.product-full');

      var href;
      var description;
      var url;
      var prodId = '';
      var prodData;
      var image;
      var query;

      if (!$(this).data('href') || (refresh && !$spp.length)) {
        href = $link.attr('href');
        $(this).data('href', href);
      } else {
        href = $(this).data('href');
      }

      if (!$container.data('image') || refresh) {
        image = $container.data('image-url');
        // check if image data attr is available, otherwise check first image
        image = _.isUndefined(image) ? $('.js-product-image').first().attr('data-src') : image;
        image = turnRelativeToAbsolute(image);
        $container.data('image', image);
      } else {
        image = $container.data('image');
      }

      // TODO: why is this stuff not using page_data?
      if ($quickshop.length) {
        url = $quickshop.find('.js-spp-link').attr('href');
        url = turnRelativeToAbsolute(url);
        prodId = $quickshop.data('product-id');
        description = $quickshop.find('.js-product-name').text();
      } else if ($spp.length) {
        if (window.location.hash) {
          url = window.location.href;
        } else {
          url = $spp.attr('data-url');
        }
        url = turnRelativeToAbsolute(url);
        prodId = $spp.data('product-id');
        description = $spp.find('.product-full__name').text();
      } else {
        url = window.location.href;
        description = $('.js-product-name').text();
      }

      if (prodcat && prodId) {
        prodData = prodcat.data.getProduct(prodId);
      }

      var encodedUrl = encodeURIComponent(url);
      var encodedDescription = encodeURIComponent(description);
      var encodedImage = encodeURIComponent(image);
      var encodedBuzzwords = null;
      var buzzWords = null;
      if (prodData && prodData.PRODUCT_BUZZWORDS) {
        buzzWords = prodData.PRODUCT_BUZZWORDS;
        encodedBuzzwords = encodeURIComponent(buzzWords);
      }

      // facebook
      if ($link.hasClass('js-share--facebook')) {
        if (_.isUndefined(image)) {
          image = '';
        }

        //?u=
        query = href + '?u=' + encodedUrl;
        //&picture
        query = query + '&picture=' + image;
        //quote
        if (buzzWords) {
          query = query + '&quote=' + encodedBuzzwords;
        } else {
          query = query + '&quote=' + encodedDescription;
        }
        //other options
        //&description
        //&caption
        $link.attr('href', query);
      }

      // twitter
      if ($link.hasClass('js-share--twitter')) {
        //?url
        query = href + '?url=' + encodedUrl;
        //&text
        if (buzzWords) {
          query = query + '&text=' + encodedBuzzwords;
        } else {
          query = query + '&text=' + encodedDescription;
        }
        //other options
        //&hashtags
        $link.attr('href', query);
      }

      // pinterest
      if ($link.hasClass('js-share--pinterest')) {
        //?url=
        query = href + '?url=' + encodedUrl;
        //&media=
        query = query + '&media=' + encodedImage;
        //&description
        if (buzzWords) {
          query = query + '&description=' + encodedBuzzwords;
        } else {
          query = query + '&description=' + encodedDescription;
        }
        $link.attr('href', query);
      }

      // vk
      if ($link.hasClass('js-share--vk')) {
        //?url=
        query = href + '?url=' + encodedUrl;
        //&image=
        query = query + '&image=' + encodedImage;
        //&title
        if (buzzWords) {
          query = query + '&title=' + encodedBuzzwords;
        } else {
          query = query + '&title=' + encodedDescription;
        }
        //other options
        //&description
        $link.attr('href', query);
      }

      // email
      if ($link.hasClass('js-share--email')) {
        //?subject
        query = href + '?subject=' + encodedDescription;
        //&body
        if (buzzWords) {
          query = query + '&body=' + encodeURIComponent(buzzWords + ' ' + url);
        } else {
          query = query + '&body=' + encodedUrl;
        }
        $link.attr('href', query);
      }

      // line
      if ($link.hasClass('js-share--line')) {
        // ?url
        query = href + '?url=' + encodedUrl;
        $link.attr('href', query);
      }
    });
  };

  $(document).on('product.quickshop.launch.inline product.quickshop.launch.inlineCarousel product.quickshop.launch.overlay', function() {
    setupShareLinks(0);
  });

  $(document).on('product.updateRoute', '.js-product', function() {
    // refresh
    setupShareLinks(1);
  });

  $(document).on('click', '.js-share', function(event) {
    var $shareLink = $(this);

    // email links can't popup
    if ($shareLink.hasClass('js-share--email')) {
      return;
    }
    event.preventDefault();
    var width = 600;
    var height = 750;
    var openUrlShare = $(this).attr('href');
    var openUrl = openUrlShare.replace(/#/g, '%23');

    // Allow for borders.
    var leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
    // Allow for title and status bars.
    var topPosition = (window.screen.height / 2) - ((height / 2) + 50);
    var windowFeatures = 'status=no,height=' + height + ',width=' + width;
    windowFeatures += ',resizable=yes,left=' + leftPosition + ',top=' + topPosition;
    windowFeatures += ',screenX=' + leftPosition + ',screenY=' + topPosition;
    windowFeatures += ',toolbar=no,menubar=no,scrollbars=no,location=no,directories=no';

    window.open(openUrl, 'sharer', windowFeatures);
  });

  $(function() {
    setupShareLinks(0);
  });
})(jQuery, window.site || {});
