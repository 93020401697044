var prodcat = prodcat || {};
prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function($, generic) {

  /*
   * Get the nearest js-product and validate that it matches the product-id if
   * the target also contained one. This means the data-product-id is optional
   * on the target element.
   *
   * This specifically target .js-product and is used to get an element that
   * has the current sku-base-id.
   */
  prodcat.ui.getNearestValidProduct = function($target) {
    $target = $($target); // make sure jquery object.

    var productId = $target.data('product-id');
    var $product = $target.closest('.js-product');
    if ($product.length === 0) {
      return false;
    }

    var jsProductSkuBaseId = $product.data('sku-base-id');
    var skuData = prodcat.data.getSku(jsProductSkuBaseId);

    // Make sure itself is valid.
    if (!skuData) {
      return false;
    }

    // If the target had a product-id then verify we're dealing with the same.
    if (productId && skuData.PRODUCT_ID !== productId) {
      return false;
    }

    return $product;
  };

  /*
   * Verify that a $product ('.js-product') is valid for the $target and
   * skuBaseId. We run into cases where there is an over-subscription and
   * emition of events that causes un wanted side effects like skuSelects
   * affecting quickshops.
   */
  prodcat.ui.isNearestValidProduct = function($product, $target, skuBaseId) {
    var sku = prodcat.data.getSku(skuBaseId);
    var product_id = $product.data('product-id');
    $target = $($target);

    if (!sku) {
      return false;
    }
    // only run on skus that come from the same product.
    if (sku['PRODUCT_ID'] !== product_id) {
      return false;
    }

    var $nearest = $target.closest('.js-product[data-product-id="' + product_id + '"]');
    return $nearest.is($product);
  };

  /*
   * Short hand for getting the proper product context.
   * Our product subtemplates are just partials, so often times we have to make
   * educated guesses for which product they exist for.
   *
   * This returns the product_id and the $element it came from.
   *
   * TODO: in the future this logic should be locked down instead of be done
   * willy nilly.
   */
  _getProductContext = function($target) {
    var product_context = null;
    // check target itself
    if ($target.data('product-id')) {
      product_context = {
        product_id: $target.data('product-id'),
        element: $target,
      };
      return product_context;
    }

    // use a new data attribute to provide product context without needing
    // js-product. js-product has a lot of baggae associated with it.
    var $contextProvider = $target.closest('[data-product-id-context]');
    if ($contextProvider.length) {
      product_id = $contextProvider.data('product-id-context');
      product_context = {
        product_id: product_id,
        element: $contextProvider,
      };
      return product_context;
    }

    // try to find via js-product
    var $product = $target.closest('.js-product');
    if ($product.length) {
      product_id = $product.data('product-id');
      product_context = {
        product_id: product_id,
        element: $product,
      };
      return product_context;
    }
    return product_context;

  };

  prodcat.ui.getProductContext = function($target) {
    var product_context = _getProductContext($target);
    var $dataproduct = $target.closest('[data-product-id]');
    if (!product_context) {
      console.warn('No product context was found but an unmatched dataproduct exists');
      return product_context;
    }
    var $product_context_element = product_context.element;
    // right now we only match on target and .js-product. Conceptually
    // this warning should probalby never fire? Not 100% sure if we should
    // always target .js-product only since that comes with a lot of baggae.
    if ($.contains($product_context_element, $dataproduct)) {
      console.warn("Matched a product context, but a nearest dataproduct parent existed");
    }

    if (!product_context['product_id']) {
      console.warn("Matched Product Context had an empty data-product-id");
      return null;
    }
    return product_context;
  };

  prodcat.ui.getProductTranslation = function(key) {
    if (!window.site || !window.site.translations || !window.site.translations.product) {
      return;
    }
    return site.translations.product[key];
  };

})(jQuery, window.generic = window.generic || {});
