var MantleUI = MantleUI || {};

(function($) {
  /*
   * RTWidget for Carousels.
   */
  var MantleRangeSliderRTWidget = MantleUI.MantleRTWidget.createSubclass(
    function(overrides, widgetKey, element) {
      MantleUI.MantleRTWidget.call(this, overrides, widgetKey, element);
      this.$slider = $(this.options.slider);
      this.noAnimation = this.options.noAnimation || false;
    }
  );

  MantleRangeSliderRTWidget.prototype.initialize = function(options) {
    var self = this;
    var $slider = this.$slider;
    var initialSlide = options.initialSlide || 0;
    // We unset the form-value becuase the director will set the value if
    // need be. Otherwise it will default to the first slide because the
    // range input will default to the first tick
    $slider.attr('data-form-value', '');
    $slider.data('starting', initialSlide);
    $slider.data('startInMiddle', !!options.initialSlideInMiddle);

    /*
     * This is what propogates to quizes to notify the mantle_form js that an
     * input has been selected. Due to how input ranages work, they always have
     * an input val. But that doesn't mean they made a "choice".
     */
    $slider.on('slider-tick-set', function(e) {
      // we want to handle this
      e.stopPropagation();
    });

    $slider.on('tick-selected', function(event, tickNum) {
      self.tickUpdate(tickNum);
    });

    Drupal.behaviors.rangeSlider.attachReal(this.element);
  };

  /*
   * initialize the carousel if our initMode is onSelection.
   */
  MantleRangeSliderRTWidget.prototype.handleSelectedTick = function() {
  };

  MantleRangeSliderRTWidget.prototype.handleTickUpdate = function(newTick) {
    this.slideRangeInput(newTick, 500, false);
  };

  MantleRangeSliderRTWidget.prototype.slideRangeInput = function(targetSlide, duration, stepCheck) {
    var $slider = this.$slider;
    if (this.noAnimation) {
      $slider.trigger('slide-to', [targetSlide, 0, false]);
      return;
    }
    $slider.trigger('slide-to', [targetSlide, duration, stepCheck]);
  };

  MantleUI.MantleRangeSliderRTWidget = MantleRangeSliderRTWidget;
}(jQuery));
