var MantleUI = MantleUI || {};
var MantleGrid = MantleGrid || {};

(function($) {
  /*
   * RTWidget for Grids.
   */
  var MantleGridRTWidget = MantleUI.MantleRTWidget.createSubclass(
    function(overrides, widgetKey, element) {
      MantleUI.MantleRTWidget.call(this, overrides, widgetKey, element);
      this.$grid = $(this.options.element);
      this.itemSelector = this.options.itemSelector;
      this.gridOptions = this.options.gridOptions;
    }
  );

  MantleGridRTWidget.prototype.initialize = function(options) {
    var $grid = this.$grid;
    var itemSelector = this.itemSelector;
    var defaultGridOptions = {
      disableStaggeredAnimation: true,
    };
    var gridOptions = this.gridOptions || {};
    var mantleGridOptions = _.extend({}, defaultGridOptions, gridOptions);
    MantleGrid.initializeGrid($grid, itemSelector, mantleGridOptions);
  };

  /*
   * initialize the carousel if our initMode is onSelection.
   */
  MantleGridRTWidget.prototype.handleFilterQuery = function(filterQuery) {
    var $grid = this.$grid;
    MantleGrid.remix($grid, filterQuery, false, {animate: true});
  };

  MantleUI.MantleGridRTWidget = MantleGridRTWidget;
}(jQuery));
