var MantleUI = MantleUI || {};

(function($) {
  /*
   * The purpose of MantleRangeTickDirector is to coordinate a bunch of widgets
   * that can understand a range slider that has ticks at discreet units. It
   * needs to be able to coordinate between widgets that understand continous
   * ranges like input="range" and discreet units like a carousel.
   *
   * Feature List:
   *
   * 1. Have the ability to define a displayed tick and a selected tick. We
   * must be able to display various tick values without *selecting* them. This
   * is for when this is used within a form.
   * 2. Filtering. This must be able to filter ticks.
   * 3. Accept a dataset. Like the mixitup dataset api. Since we are
   * coordinating mutliple widgets, none of them should *own* the data. The
   * data should exist within the Director.
   * 4. Set an initial tick.
   * 5. Align a continous range stream to discreet tick values. This means that
   * feature like lock to tick must work. You might move a range slider but not
   * enough to cross over into another tick.
   * 6. The Director should decide when that threshold is met.
   * 7. The dataset api should also handle sorting if possible. Unsure as the
   * widgets would need to be able to function with that.
   * 
   * So this is really trying to make a generalized version of the range slide
   * carousel found on Becca, Toofaced, and Bobbi.
   *
   */
  var MantleRangeTickDirector = function(dataItems, overrides) {
    var defaults = {
      initialSlideInMiddle: false,
      selectionMode: 'currentTick',
    };

    overrides = overrides || {};
    var settings = Object.assign({}, defaults, overrides);

    this.widgets = {};
    this.dataItems = dataItems;
    this.settings = settings || {};
    this.currentTick = 0;
    this.selectedTick = null;
    this.selectionMode = settings.selectionMode;
    this.initialized = false;
    this.filterQuery = null; // current filterQuery

    if (!!settings.initialSlideInMiddle && settings.slideCount) {
      this.currentTick = Math.floor(settings.slideCount / 2);
    }
  };

  MantleRangeTickDirector.prototype.addWidget = function(widget, widgetKey) {
    widgetKey = widgetKey || widget.widgetKey;
    if (!widgetKey) {
      throw new Error('Widget must have a key');
    }

    widget.director = this;

    this.widgets[widgetKey] = widget;
  };

  MantleRangeTickDirector.prototype.initialize = function() {
    var self = this;
    // Update all the widgets except the caller
    for (var wk in this.widgets) {
      var widget = this.widgets[wk];
      if (!!widget.skipInitialize) {
        continue;
      }
      this.initializeWidget(wk);
    }

    // so right now there is an issue with the range slider where it needs to
    // aniamte for 1ms. which means calling its initialize isn't synchronous.
    // So we have a small timeout. Need to make it sync or have widget be able
    // to return promises for async intialization.
    window.setTimeout(function() {
      if (self.settings.startSelected) {
        // I think we're good with this being here.
        self.setSelectedTick(self.currentTick);
      }
      self.initialized = true;
    }, 100);
  };

  MantleRangeTickDirector.prototype.initializeWidget = function(widgetKey) {
    var initOptions = {};
    initOptions.initialSlide = this.currentTick;
    initOptions.initialSlideInMiddle = !!this.settings.initialSlideInMiddle;

    var widget = this.widgets[widgetKey];
    if (!!widget.initialize) {
      widget.initialize(initOptions);
    }
  };

  /*
   * Set a MantleGrid compatible filterQuery.
   *
   * TODO: The Filter/Dataset logic should be extracted from MantleGrid. I'd
   * prefer not having to call MantleGrid.Filter in order to get the resultant
   * dataset.
   */
  MantleRangeTickDirector.prototype.setFilterQuery = function(filterQuery, context) {
    this.filterQuery = filterQuery;

    for (var wk in this.widgets) {
      var widget = this.widgets[wk];
      if (widget.handleFilterQuery) {
        widget.handleFilterQuery(filterQuery, context);
      }
    }

    $(this).trigger('RTD:FilteredByQuery', [filterQuery, context]);
  };

  MantleRangeTickDirector.prototype.setSelectedTick = function(selectedTick) {
    if (this.selectedTick === selectedTick) {
      // This should realistically only happen when you click on a slide as the
      // setTick and slide click handler can both set the selectedTick.
      return;
    }

    this.selectedTick = selectedTick;

    for (var wk in this.widgets) {
      var widget = this.widgets[wk];
      if (widget.handleSelectedTick) {
        widget.handleSelectedTick(selectedTick);
      }
    }

    $(this).trigger('RTD:SelectedTick', selectedTick);
  };

  MantleRangeTickDirector.prototype.getCurrentTick = function() {
    return this.currentTick;
  };

  MantleRangeTickDirector.prototype.getSelectedTick = function() {
    return this.selectedTick;
  };

  MantleRangeTickDirector.prototype.tickUpdate = function(widgetKey, newTick) {
    this.currentTick = newTick;

    // Widgets can't provide real tick updates that propogate until we've
    // initialized. This is to prevent tickUpdate calls that are really just
    // inits.
    if (!this.initialized) {
      return;
    }

    // This globally locks currentTick and selectedTick.
    // Otherwise Widgets themselves can chose when they trigger a SelectedTick
    if (this.selectionMode === 'currentTick') {
      this.setSelectedTick(newTick);
    }

    // For now the selectedTick gets set after init.
    // Not 100% sure this shoudl always be the case.

    // Update all the widgets except the caller
    for (var wk in this.widgets) {
      if (wk === widgetKey) {
        continue;
      }
      var widget = this.widgets[wk];
      if (widget.handleTickUpdate) {
        widget.handleTickUpdate(newTick);
      }
    }
  };

  /*
   * Base Widget Class.
   */
  var MantleRTWidget = function(overrides, widgetKey, element) {
    overrides = overrides || {};

    var defaults = this.optionDefaults;
    var options = Object.assign({}, defaults, overrides);

    this.director = null;
    this.widgetKey = widgetKey || options.widgetKey;
    this.element = element || options.element;
    this.initMode = options.initMode;

    this.options = options;
  };

  MantleRTWidget.prototype.optionDefaults = {
    initMode: 'default',
  };

  MantleRTWidget.createSubclass = function(cls) {
    cls.prototype = Object.create(MantleRTWidget.prototype);
    cls.prototype.constructor = cls;
    return cls;
  };

  MantleRTWidget.prototype.setSelectedTick = function(selectedTick) {
    return this.director.setSelectedTick(selectedTick);
  };

  MantleRTWidget.prototype.getCurrentTick = function() {
    return this.director.getCurrentTick();
  };

  MantleRTWidget.prototype.getSelectedTick = function() {
    return this.director.getSelectedTick();
  };

  MantleRTWidget.prototype.tickUpdate = function(tickNum) {
    var widgetKey = this.widgetKey;
    return this.director.tickUpdate(widgetKey, tickNum);
  };

  MantleUI.MantleRangeTickDirector = MantleRangeTickDirector;
  MantleUI.MantleRTWidget = MantleRTWidget;
}(jQuery));

