(function($, site, generic) {
  site.videos = {
    isMobile: {
      Android: function() {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
      },
      any: function() {
        return this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows();
      }
    },

    open: function(opts) {
      var provider = !_.isEmpty(opts.provider) ? opts.provider : '';

      if (provider === 'zentrick') {
        this.openZentrick(opts);
      } else if (provider === 'youtube') {
        this.openYouTube(opts);
      } else if (provider === 'vimeo') {
        this.openVimeo(opts);
      } else if (provider === 'html5') {
        this.openHTML5(opts);
      } else if (provider === 'elc-video') {
        this.openHTML5(opts);
      } else {
        console.log('Video provider not yet supported.');
      }
    },

    openZentrick: function(opts) {
      var publicationId = opts.zentrickId;
      var context = !_.isEmpty(opts.context) ? opts.context : document;

      if (!!site.isZentrickPlayerAPIReady && !_.isEmpty(publicationId)) {
        var content = '<div class="js-content-block-video-placeholder"></div>';
        var options = {
          content: content,
          className: 'colorbox--video',
          height: 'auto',
          width: '100%',
          maxWidth: 1024,
          maxHeight: 768
        };

        // Remove existing videos
        $('.zentrick-video').remove();

        // Create a new video container element
        var id = 'p' + +new Date();
        var $videoContainer = $('<div></div>').attr({
          'class': 'hidden zentrick-video',
          'id': id
        });
        $videoContainer
          .append($('<div />').attr('class', 'embed-container').append($('<div />').attr('id', id + '-inner')))
          .appendTo('body');

        // Initialize the player
        site.zentrickPlayer = new zentrick.Player(
          id + '-inner',
          publicationId,
          { html5: 1 }
        );

        // Add our new video player to an overlay
        options.content = '<div class="js-content-block-video-placeholder">' + $('#' + id).html() + '</div>';
        options.fastIframe = false;

        generic.overlay.launch(options);
      } else {
        console.log('Zentrick not ready or missing publication id.');
      }
    },

    openYouTube: function(opts) {
      var ytId = opts.youTubeId;
      var iframeId = false;
      var context = !_.isEmpty(opts.context) ? opts.context : document;
      var content = '<div class="js-content-block-video-placeholder youtube-video"></div>';
      var scroll = $(window).scrollTop();
      function createVideoPlayerDiv($elem, i) {
        $elem.removeClass('js-content-block-video-placeholder');
        var id = $elem.attr('class') + '-' + i;
        $elem
          .parent()
          .append('<div />')
          .children('div')
          .attr('id', id);
        return id;
      }

      function addVideo(ytId) {
      /*global YT */
        new YT.Player(iframeId, {
          height: '100%',
          width: '100%',
          videoId: ytId,
          playerVars: {
            autoplay: 0,
            modestbranding: 0,
            controls: 1,
            showinfo: 0,
            rel: 0,
          },
          events: {
            'onReady': function(event) {
            // Don't autoplay on any mobile device
              if (!site.videos.isMobile.any()) {
                event.target.playVideo();
              }
            },
            'onStateChange': function() {
            // stuff
            }
          }
        });
      }

      var options = {
        content: content,
        className: 'colorbox--video',
        height: 'auto',
        width: '100%',
        maxWidth: 1024,
        maxHeight: 768,
        onLoad: function() {
          var _this = this;
          _this.$cbox = $('#colorbox.colorbox--video', context);
          // initially hide close button to get around wonky animation
          $('#cboxClose', _this.$cbox).hide();
        },
        onComplete: function() {
          var _this = this;
          _this.$cbox = $('#colorbox.colorbox--video', context);

          // Create a new div for the player to take over inside of the colorbox
          iframeId = createVideoPlayerDiv($('.js-content-block-video-placeholder', _this.$cbox), Math.floor(+new Date() / 1000));

          // Remove our empty container
          $('.js-content-block-video-placeholder', _this.$cbox).remove();

          // Instantiate the video
          addVideo(ytId);

          // add container to make video embed responsive
          $('iframe', _this.$cbox).wrap('<div class="embed-container"></div>');

          $.colorbox.resize();

          // show close button once wonky animation ends
          $('#cboxClose', _this.$cbox).show();
        }
      };

      if (opts.openOverlay) {
        generic.overlay.launch(options);
      } else {
        opts.$content.html(content);
        iframeId = createVideoPlayerDiv($('.js-content-block-video-placeholder', opts.$content), Math.floor(+new Date() / 1000));
        addVideo(ytId);
        opts.$content.trigger('video.loaded');
      }

      $(document).on('videos.pause', function(event) {
        $('#' + iframeId).each(function() {
          if ($(this).get(0).contentWindow) {
            $(this).get(0).contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
          }
        });
      });
    },

    openVimeo: function(opts) {
      var vimeoId = opts.vimeoId;
      var url = '/elc-embed/vimeo/' + vimeoId;

      $.ajax(url, {
        success: function(data) {
          var context = !_.isEmpty(opts.context) ? opts.context : document;
          var content = data['html'];

          var options = {
            content: content,
            className: 'colorbox--video',
            height: 'auto',
            width: '100%',
            maxWidth: 1024,
            maxHeight: 768,
            onLoad: function() {
              var _this = this;
              _this.$cbox = $('#colorbox.colorbox--video', context);
              // initially hide close button to get around wonky animation
              $('#cboxClose', _this.$cbox).hide();
            },
            onComplete: function() {
              var _this = this;
              _this.$cbox = $('#colorbox.colorbox--video', context);
    
              // add container to make video embed responsive
              $('iframe', _this.$cbox).wrap('<div class="embed-container"></div>');
    
              $.colorbox.resize();
    
              // show close button once wonky animation ends
              $('#cboxClose', _this.$cbox).show();
            }
          };

          if (opts.openOverlay) {
            generic.overlay.launch(options);
          } else {
            opts.$content.html(content);
            opts.$content.find('iframe').attr({ 'allow': 'autoplay', 'height': '100%', 'width': '100%' });
            opts.$content.trigger('video.loaded');
          }
        },
      });
    },

    openHTML5: function(opts) {
      var content = opts.content;
      var context = !_.isEmpty(opts.context) ? opts.context : document;

      var options = {
        content: content,
        className: 'colorbox--video',
        height: 'auto',
        width: '100%',
        maxWidth: 1024,
        maxHeight: 768,
        onLoad: function() {
          var _this = this;
          _this.$cbox = $('#colorbox.colorbox--video', context);
          // initially hide close button to get around wonky animation
          $('#cboxClose', _this.$cbox).hide();
        },
        onComplete: function() {
          var _this = this;
          _this.$cbox = $('#colorbox.colorbox--video', context);

          // add container to make video embed responsive
          $('video', _this.$cbox).wrap('<div class="embed-container"></div>');

          $.colorbox.resize();

          // show close button once wonky animation ends
          $('#cboxClose', _this.$cbox).show();

          $('video', _this.$cbox).get(0).play();
        }
      };

      if (opts.openOverlay) {
        generic.overlay.launch(options);
      } else {
        opts.$content.html(opts.content);
        opts.$content.find('video').length ? opts.$content.find('video').get(0).play() : false;
        opts.$content.trigger('video.loaded');
      }

      $(document).on('videos.pause', function(event) {
        $('video').each(function() {
          $(this).get(0).pause();
        });
      });
    }
  };
})(jQuery, window.site || {}, window.generic || {});
