/*jshint sub:true*/
var MantleInventoryStateConfig = MantleInventoryStateConfig || {};
var prodcat = prodcat || {};
prodcat.control = prodcat.control || {};

(function($) {
  function swap(json) {
    var ret = {};
    for (var key in json) {
      ret[json[key]] = key;
    }
    return ret;
  }

  var arrayIncludes = function(arr, search) {
    return !!~arr.indexOf(search);
  };

  var control = {};

  var statusIds = {
    inventory: {
      shoppable: 1,
      outOfStock: 2,
      comingSoon: 3,
      doNotDisplay: 4,
      inactive: 5,
      promo: 6,
      soldOut: 7,
    },
    lifeOfProduct: {
      limitedLife: 1,
      basicReorder: 2,
    }
  };

  var inventoryMap = swap(statusIds.inventory);
  var lifeOfProductMap = swap(statusIds.lifeOfProduct);

  var inventoryStateSchema = {
    shoppable: true,
    outOfStock: {
      Waitlist: true,
      Backorder: true,
      Disabled: true,
    },
    comingSoon: {
      Waitlist: true,
      Backorder: true,
      Disabled: true,
    },
    soldOut: {
      Waitlist: true,
      Disabled: true,
    },
    doNotDisplay: true,
    promo: true,
    inactive: true,
  };

  // Create inventory state flag schema. This is copied later on with the
  // correspdoning flags flipped true.
  var flagsTemplate = {};
  for (var invKey in statusIds.inventory) {
    flagsTemplate[invKey] = false;
  }

  for (var lifeKey in statusIds.lifeOfProduct) {
    flagsTemplate[lifeKey] = false;
  }

  for (var invStateKey in inventoryStateSchema) {
    var invStateVal = inventoryStateSchema[invStateKey];
    if (invStateVal === true) {
      flagsTemplate[invStateKey] = false;
      continue;
    }
    for (var invStateSubKey in invStateVal) {
      flagsTemplate[invStateKey + '_' + invStateSubKey] = false;
    }
  }

  // TODO replace with proxy object when available?
  var generateFlagsProxy = function(inventoryState = {}) {
    var flags = Object.assign({}, flagsTemplate);

    ['inventoryKey', 'lifeOfProductKey', 'inventoryStateKey'].forEach(key => {
      if (inventoryState.hasOwnProperty(key)) {
        flags[inventoryState[key]] = true;
      }
    })

    return flags;
  };

  var _match_sku_filter_single = function(skuDataL2, info) {
    var _match = true;
    _.each(info, function(value, key) {
      if (skuDataL2[key] !== value) {
        _match = false;
        return;
      }
    });
    return _match;
  };

  var _match_sku_filter = function(skuDataL2, conditions) {
    var match_all = false;
    _.each(conditions, function(info, i) {
      var _match = _match_sku_filter_single(skuDataL2, info);
      if (_match) {
        match_all = true;
      }
    });

    return match_all;
  };

  /*
   * Enhanced Inventory State
   *
   * Traditionally a lot of inventory comes down to a combination of various
   * flags like INVENTORY_STATUS, isShoppable, LIFE_OF_PRODUCT, etc. Enhanced
   * Inventory State is an attempt to centralize and coelesce these state
   * combinations so each widget doesn't have touch these implementation
   * details directly.
   *
   * Any derived state found in this function should be portable. Meaning that
   * things like waitlist conditions needs to be configurable outside of this
   * function.
   *
   * The semantics of this are def WIP. But it was driving me insane.
   *
   * inventoryStateKey: Should represent the entire inventory state i.e. coming
   * soon + waitlist vs coming soon + disabled CTA.
   *
   */
  // eslint-disable-next-line complexity
  control.getEnhancedInventoryState = function(skuDataL2) {
    var inventoryState = this._getEnhancedInventoryState(skuDataL2) || {};
    var flags = generateFlagsProxy(inventoryState);
    inventoryState.f = flags;

    return inventoryState;
  };

  // eslint-disable-next-line complexity
  control._getEnhancedInventoryState = function(skuDataL2) {
    var hasWaitlist = Drupal.settings.common && Drupal.settings.common.has_waitlist;
    var waitlistConditions = Drupal.settings.common && Drupal.settings.common.waitlist_conditions;
    var hasBackorder = Drupal.settings.common && Drupal.settings.common.has_backorder;
    var backorderConditions = Drupal.settings.common && Drupal.settings.common.backorder_conditions;

    var inventoryState = {};

    if (!skuDataL2) {
      // eslint-disable-next-line no-console
      console.warn('skuDataL2 missing. This should not happen.');
      return null;
    }

    // default state.
    inventoryState['inventoryKey'] = inventoryMap[skuDataL2.INVENTORY_STATUS];
    inventoryState['inventoryStateKey'] = inventoryState['inventoryKey'];
    inventoryState['lifeOfProductKey'] = 'limitedLife';

    if (skuDataL2.LIFE_OF_PRODUCT) {
      inventoryState['lifeOfProductKey'] = lifeOfProductMap[skuDataL2.LIFE_OF_PRODUCT];
    }

    var matchesWaitlistConditions = _match_sku_filter(skuDataL2, waitlistConditions);
    var matchesBackorderConditions = _match_sku_filter(skuDataL2, backorderConditions);

    inventoryState.backorder = matchesBackorderConditions;

    // Temp OOS.
    if (skuDataL2.INVENTORY_STATUS === statusIds.inventory.outOfStock) {
      inventoryState['inventoryStateKey'] = 'outOfStock_Disabled';

      if (hasWaitlist && matchesWaitlistConditions) {
        inventoryState['inventoryStateKey'] = 'outOfStock_Waitlist';
      } else if (hasBackorder && matchesBackorderConditions) {
        // Normal disabled.
        inventoryState['inventoryStateKey'] = 'outOfStock_Backorder';
      }
    }

    if (skuDataL2.INVENTORY_STATUS === statusIds.inventory.comingSoon) {
      if (hasWaitlist && matchesWaitlistConditions) {
        inventoryState['inventoryStateKey'] = 'comingSoon_Waitlist';
      } else {
        inventoryState['inventoryStateKey'] = 'comingSoon_Disabled';
      }
    }

    if (skuDataL2.INVENTORY_STATUS === statusIds.inventory.soldOut) {
      if (hasWaitlist && matchesWaitlistConditions) {
        inventoryState['inventoryStateKey'] = 'soldOut_Waitlist';
      } else {
        inventoryState['inventoryStateKey'] = 'soldOut_Disabled';
      }
    }

    return inventoryState;
  };

  // eslint-disable-next-line complexity
  control.getInventoryStateReaction = function(skuDataL2) {
    var inventoryState = this.getEnhancedInventoryState(skuDataL2);
    if (!inventoryState) {
      // eslint-disable-next-line no-console
      console.warn('skuDataL2 missing. This should not happen.');
      return {};
    }

    // Prime reaction with inventoryState.
    var reaction = Object.assign({}, inventoryState);

    // If conf based state config is available. Add those config options to
    // reaction.
    var inventoryStateKey = reaction.inventoryStateKey;
    var stateConfig = this.getStateConfig(inventoryStateKey);

    if (stateConfig) {
      reaction = Object.assign(reaction, stateConfig);
    }

    // Default to deny first.
    reaction['shoppable'] = false;
    reaction['waitlist'] = false;

    if (arrayIncludes([
      'shoppable',
      'soldOut_Backorder',
      'outOfStock_Backorder',
      'comingSoon_Backorder',
    ], reaction.inventoryStateKey)
    ) {
      reaction['shoppable'] = true;
    }

    // Waitlist is not shoppable
    if (arrayIncludes([
      'soldOut_Waitlist',
      'outOfStock_Waitlist',
      'comingSoon_Waitlist',
    ], reaction.inventoryStateKey)
    ) {
      reaction['waitlist'] = true;
    }

    return reaction;
  };

  control.getStateConfig = function(inventoryStateKey) {
    var stateConfig = MantleInventoryStateConfig[inventoryStateKey];
    return stateConfig;
  };

  prodcat.control = control;
})(jQuery);
