(function($, generic, rb) {
  generic.rb = generic.rb || {};

  /**
    * This method provides access to resource bundle values that have been
    * written to the HTML in JSON format. The file that outputs these values
    * must be included in the .html as a script tag with the desired RB name
    * as a query string parameter.
    *
    * @class ResourceBundle
    * @namespace generic.rb
    *
    * @memberOf generic
    * @methodOf generic
    * @requires generic.Hash
    *
    * @example Inline data
    *
    *    <script src="/js/shared/v2/internal/resource.tmpl?rb=account"></script>
    *
    * @example Script retrieval of data values
    *
    *    var myBundle = generic.rb("account");
    *    myBundle.get("err_please_sign_in");
    *
    * @param {String} rbGroupName name of resource bundle needed
    *
    * @returns {Object} An object that provides the main get method
    *
    */
  generic.rb = function(rbGroupName) {
    var findResourceBundle = function(groupName) {
      if (groupName && rb) {
        var rbName = groupName;
        var rbHash = generic.Hash(rb[rbName]);
        if (rbHash) {
          return rbHash;
        } else {
          return {};
        }
      } else {
        return {};
      }
    };

    var resourceBundle = findResourceBundle(rbGroupName);

    var returnObj = {
      /**
      * @public This method will return the value for the requested Resource Bundle key.
      * If the key is not found, the key name will be returned.
      *
      * @param {String} keyName key of desired Resource Bundle value
      *
      * @returns {String} The value of the key or the key name itself.
      *
      */
      get: function(keyName) {
        if (!generic.isString(keyName)) {
          return null;
        }
        var val = resourceBundle.get(keyName);
        if (val) {
          return val;
        } else {
          return keyName;
        }
      }
    };
    return returnObj;
  };
})(jQuery, window.generic || {}, window.rb || {});
