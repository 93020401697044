var ELCFavoritesManager = (function(ELCFavoritesManager, $, generic) {

  /*
   * Call user.getFavorites and return just a skuBaseId hash.
   */
  ELCFavoritesManager.getUserFavorites = function() {
    var deferred = $.Deferred();

    generic.jsonrpc.fetch({
      method: 'user.getFavorites',
      onSuccess: function(jsonRpcResponse) {
        var rpcResponse = jsonRpcResponse.getValue();
        var skuProducts = rpcResponse.skus;
        var favorites = {};
        if (skuProducts.length) {
          _.each(skuProducts, function(sku) {
            if (!sku.SKU_ID) {
              return;
            }

            var match = sku.SKU_ID.match('[0-9]+');
            if (match) {
              var skuBaseId = match[0];
              // for now we're not storing all the data returned
              // from call. Just the skuBaseId.
              favorites[skuBaseId] = skuBaseId;
            }
          });
        }
        deferred.resolve(favorites);
      }
    });
    return deferred.promise();
  };

  // Init the PGState used to store the favorites data locally.
  var product_fav_options = {
    'init': ELCFavoritesManager.getUserFavorites,
    'expire': 60 * 24, // 1 day
  };

  ELCFavoritesManager.favorites_state = new JSBoot.PGState('product_fav', product_fav_options);

  /*
   * refreshFavorites from PG and update local data.
   */
  ELCFavoritesManager.refreshFavorites = function() {
    var self = this;
    var promise = self.getUserFavorites();
    promise.then(function(favorites) {
      self.favorites_state.updateSubjectValue('faves', favorites);
    });
  };

  /*
   * Subscribe to favorites state updates. This follows the
   * BehaviorSubject.subscribe pattern where if the data already exists,
   * subscribe will still run.
   */
  ELCFavoritesManager.subscribe = function(callback) {
    var subject = ELCFavoritesManager.favorites_state.getSubject('faves');
    return subject.subscribe(callback);
  };

  /*
   * On skuSelect, update the favorites button based on local favorite state.
   * This will not trigger a remote call.
   */
  $(document).on('product.skuSelect', '.js-product', function(e, skuBaseId) {
    // If there is no local state. Then this favorite button will be updated
    // when the attach runs and RPC returns with fav info.
    var fav_state = ELCFavoritesManager.favorites_state._get_local('faves');
    if (!fav_state || !fav_state.value) {
      return;
    }

    var favorites = fav_state.value;
    var state = 'success';
    if (!favorites[skuBaseId]) {
      state = 'removed'
    }
    $(document).trigger('product:favorite:update', [skuBaseId, state]);
  });

  /*
   * On successful add to favorites rpc, refresh the favorites list.
   */
  $(document).on('product.favorites_rpc', function(e, action, skuBaseId, disable_tooltip) {
    ELCFavoritesManager.refreshFavorites();
  });

  Drupal.behaviors.productFavManager = {
    attach: function(context) {
      // Bind favorite button to subscribe to the favorite state.
      ELCFavoritesManager.subscribe(function(favorites) {
        var $favoriteIndicators = $('.js-add-to-favorites', context);
        $favoriteIndicators.each(function(i, obj) {
          var $favButton = $(obj);
          var skuBaseId = $favButton.data('sku-base-id');
          if (!skuBaseId) {
            return;
          }

          var state = 'success';
          if (!favorites[skuBaseId]) {
            state = 'removed';
          }
          $(document).trigger('product.favorites', [$favButton, state, skuBaseId, true]);
        });
      });
    }
  }

  return ELCFavoritesManager;

})(ELCFavoritesManager || {}, jQuery, window.generic = window.generic || {});
