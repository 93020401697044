(function($, generic) {
  /**
   * This method is a getter or setter for changing or retrieving a cookie value.
   *
   * @class cookie
   * @namespace generic.cookie
   *
   * @param {string} name The name of the cookie to set or retrieve.
   * @param {string} value *OPTIONAL* When passed, this is the new value of the cookie.
   * @param {object} props *OPTIONAL* Object used to set additional props for the cookie.
   *
   * @return {string} When name is only passed, it will return the value of that cookie when available.
   */
  generic.cookie = function(name, value, props) {
    var c = document.cookie;
    if (arguments.length === 1) {
      var matches = c.match(new RegExp('(?:^|; )' + name + '=([^;]*)'));
      if (matches) {
        matches = decodeURIComponent(matches[1]);
        try {
          return JSON.parse(matches); //Object
        } catch (e) {
          return matches; //String
        }
      } else {
        return undefined;
      }
    } else {
      props = props || {};
      var exp = props.expires;
      if (typeof exp === 'number') {
        var d = new Date();
        d.setTime(d.getTime() + exp * 24 * 60 * 60 * 1000);
        exp = props.expires = d;
      }
      if (exp && exp.toUTCString) {
        props.expires = exp.toUTCString();
      }

      value = encodeURIComponent(value);
      var updatedCookie = name + '=' + value;
      var propName;

      for (propName in props) {
        updatedCookie += '; ' + propName;
        var propValue = props[propName];
        if (propValue !== true) {
          updatedCookie += '=' + propValue;
        }
      }

      document.cookie = updatedCookie;
    }
  };
})(jQuery, window.generic || {});
