var MantleReviews = MantleReviews || {};
var MantlePD = MantlePD || {};
var MantleSettings = MantleSettings || {};

(function($) {
  /*
   * Modify the Mantle Grid dataitem with the conf based Derived Fields.
   */
  var applyMantleGridDataItemAlter = function(item, metadata, dataItemConf) {
    var prodData = metadata.prodData;
    var pdManifest = metadata.pdManifest;
    // Since we are regenerating from dataItemConf. Remove the keys that this
    // conf controls. So if L1 is soldout, but L2 is not, soldout will properly
    // remove.
    for (var dataitemKey in dataItemConf) {
      delete item[dataitemKey];
    }

    // Mimic the form that shows up serverside.
    var fakeProd = {
      pd_manifest: pdManifest,
      MISC_FLAG_TEXT: prodData.MISC_FLAG_TEXT,
    };
    var derived = MantlePD.derive_all_fields(fakeProd, dataItemConf);
    if (derived) {
      for (var key in derived) {
        item[key] = true;
      }
    }
  };

  /*
   * This provides brand level site wide grid sorting attrs.
   */
  /* eslint-disable complexity */
  $(document).on('mantle-grid:init-dataItem', function(e, item, $gridItem, metadata) {
    if (!item.is_product) {
      return;
    }

    var prodData = metadata.prodData;
    var dataItemConf = MantleSettings.mantle_grid_dataitem_conf;
    applyMantleGridDataItemAlter(item, metadata, dataItemConf);

    var page_id = prodData.PROD_BASE_ID;
    // Only run if MantleReviews/PR is loaded
    if (!!MantleReviews.getData) {
      var subject = MantleReviews.getData(page_id);
      subject.subscribe(function(snippetRenderItem) {
        var rollup = snippetRenderItem.rollup;
        var rating = rollup.average_rating;
        item.rating = parseFloat(rating);
      });
    }
  });
})(jQuery);
