var generic = generic || {},
    site = site || {},
    Drupal = Drupal || {},
    JSBoot = JSBoot || {},
    drupalSettings = Drupal.settings || {},
    FB = FB || '';

(function($, loadjs) {
  site.facebook = (function() {
    var nodes = {};
    var _getDOMNodes = function() {
      nodes.container = $('.js-social-login-container');
      nodes.form = $('#facebook_signin');
      nodes.appIdInput = nodes.form.find('input[name="appId"]');
      nodes.tokenInput = nodes.form.find('input[name="token"]');
      nodes.newAccountContainer = $('.js-new-account');
      nodes.errorContainer = nodes.newAccountContainer.find('.error_messages');
      nodes.fbDisclaimerError = $('.js-fb-disclaimer-error');
      nodes.fbOverlay = $('.js-fb-overlay, .js-fb-disclaimer-error');
  };

    var _initFB = function(appId) {
      FB.init({
        appId: appId,
        cookie: true,
        xfbml: true,
        version: 'v2.10'
      });
      FB.AppEvents.logPageView();
      FB.Event.subscribe('xfbml.render', _showFbContainer);
    };

    var _showFbContainer = function() {
      nodes.container.removeClass('hidden');
    };

    var _getAppId = function() {
      if (drupalSettings.common && drupalSettings.common.fb_login_app_id) {
        return drupalSettings.common.fb_login_app_id;
      }
    };

    $('.js-facebook_disclaimer').on('click', function() {
      if ($(this).is(':checked')) {
        nodes.fbOverlay.hide();
      } else {
        nodes.fbOverlay.show();
      }
    });

    $('.js-fb-overlay').on('click', function() {
      nodes.fbDisclaimerError.removeClass('hidden');
    });

    // eslint-disable-next-line complexity
    var _init = function() {
      _getDOMNodes();

      // Make sure that the form action points to the SSL version otherwise it fails to log in
      var action_url = '/account/signin.tmpl';
      if (location.pathname.match('/checkout/index.tmpl') || location.pathname.match('/checkout/samples.tmpl')) {
        action_url = '/checkout/index.tmpl';
      }

      // Non-single page checkout setting
      if (location.pathname.match('/checkout/checkout.tmpl') || location.pathname.match('/checkout/signin.tmpl')) {
        action_url = '/checkout/checkout.tmpl';
      }

      nodes.form.attr('action', 'https://' + window.location.hostname + action_url);

      // Set the RETURN_URL to the currently viewed page
      // Redirect to profile preferences page when clicked on order confirmation
      var returnUrlInput = $('<input>').attr({
        type: 'hidden',
        name: 'RETURN_URL'
      });

      if (location.pathname.match('/checkout/confirm.tmpl')) {
        var returnUrl = '/account/index.tmpl';
        returnUrlInput.val(returnUrl);
        returnUrlInput.appendTo(nodes.form);
      }

      // If URL contains RETURN_URL in query
      if (location.search.match('RETURN_URL')) {
        var return_url;
        if (typeof URLSearchParams === 'function') {
          var query_string = new URLSearchParams(location.search);
          return_url = query_string.get('RETURN_URL');
        } else {
          return_url = getUrlParameter('RETURN_URL');
        }
        if (typeof return_url !== 'undefined') {
          returnUrlInput.val(decodeURIComponent(return_url));
          returnUrlInput.appendTo(nodes.form);
        }
      }

      var appId = _getAppId();

      if (appId && (typeof FB === 'object')) {
        _initFB(appId);
      }
    };

    var _statusChangeCallback = function(response) {
      if (response.status === 'connected') {
        // Extract authResponse.accessToken
        var token = response.authResponse.accessToken;
        nodes.tokenInput.val(token);
        nodes.form.submit();
      } else if (response.status === 'not_authorized') {
        // display error that app hasnt been authorized
        generic.showErrors([{text: site.facebook.not_authorized}], nodes.errorContainer);
      } else {
        generic.showErrors([{text: site.facebook.login_error}], nodes.errorContainer);
      }
    };

    /* Fallback function if URLSearchParams is not available
     * Returning get parameter value from the current URL
     */
    var getUrlParameter = function(name) {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      var results = regex.exec(location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    var fbSubject = new JSBoot.BehaviorSubject();

    return {
      init: function() {
        _init();
        fbSubject.next(true);
      },
      checkLoginState: function() {
        FB.getLoginStatus(function(response) {
          _statusChangeCallback(response);
        });
      },
      hasLoadedFBLibs: function() {
        return loadjs.isDefined('facebooksdk');
      },
      loadFBLibs: function(callback) {
        // Note that callback is called regardless of whether we've download FB
        // lib.
        var locale = drupalSettings.fb_sdk_locale || 'en_US';
        var src = '//connect.facebook.net/' + locale + '/sdk.js';
        if (!loadjs.isDefined('facebooksdk')) {
          loadjs([src], 'facebooksdk');
        }
        if (typeof callback === 'undefined') {
          callback = function() {
            // eslint-disable-next-line
            console.info('loadjs FB libs loaded');
          };
        }
        loadjs.ready('facebooksdk', callback);
      },
      /*
       * This will wait for the facebook stuff to be ready. If the sdk has not
       * been loaded it will loaded it on demand.
       */
      callWhenFBReady: function(callback) {
        if (site.facebook.hasLoadedFBLibs()) {
          site.facebook.loadFBLibs();
        }
        fbSubject.subscribe(callback);
      },
    };
  }());

  /*
   * This is called by the facebook sdk. Which is why we have no callbacks for
   * loadjs.
   */
  window.fbAsyncInit = function() {
    site.facebook.init();
  };

  /*
   * Load the sdk onload.
   */
  $(window).on('load', function() {
    site.facebook.loadFBLibs();

    $('.js-facebook-button').on('click', function(e) {
      e.preventDefault();
      site.facebook.callWhenFBReady(function() {
        FB.login(function(response) {
          if (response.status === 'connected') {
            site.facebook.checkLoginState();
          }
        }, {scope: 'public_profile,email'});
      });
    });
  });
})(jQuery, window.loadjs);
