/*
 * PowerReviewsApiBroker
 *
 * Copies the PowerReviewsBroker semantics but is backed by the Read API. This
 * has the advantage of not requiring an intermediary DOM write from the
 * POWERREVIEWS.display.render function.
 */

var JSBoot = JSBoot || {};
var PowerReviewsAPI = PowerReviewsAPI || {};
var MantleReviewPRConf = MantleReviewPRConf || {};
var MantleReviews = MantleReviews || {};
var site = site;

var PowerReviewsApiBroker = function() {
  this.snippet_map = {};
  this.snippet_data = {};
  this.api = PowerReviewsAPI.getPowerReviewsApiFromSettings(MantleReviewPRConf);

  // SubjectHash tracking the side render complete
  this.siderender_subjecthash = new JSBoot.SubjectHash();

  var configurationSubject = this.api.getConfiguration();

  // Keep updated with configuration.
  var self = this;
  this.configuration = null;
  configurationSubject.subscribe(function(configuration) {
    if (configuration) {
      self.configuration = configuration;
    }
  });
};

/*
 * Handles the case where we're adding a CategorySnippet. The general flow here
 * is that we log the request in snippet_map and then delete the sub component
 * so that POWERREVIEWS.display.render won't try to render it.
 */
PowerReviewsApiBroker.prototype.addCategorySnippet = function(pr_product) {
  if (!pr_product.components.CategorySnippet) {
    return;
  }

  var self = this;
  var page_id = pr_product.page_id;

  var snippet_id = pr_product.components.CategorySnippet;
  var snippet_el = null;
  // snippet el still required an id to work.
  if (typeof snippet_id !== 'string') {
    snippet_el = snippet_id;
    snippet_id = snippet_el.id;
  }

  if (!snippet_id) {
    /* eslint-disable no-console */
    console.warn('The snippet_el api still requires the element have a unique id');
    return;
  }

  this.snippet_map[page_id] = {
    pr_product: pr_product,
    snippet_id: snippet_id,
    snippet_el: snippet_el,
    rendered: false,
  };

  // We don't want POWERREVIEWS rendering this.
  delete pr_product.components.CategorySnippet;

  var subject = self.siderender_subjecthash.get(page_id);

  if (!pr_product.disableSyncSnippetRender) {
    // Broker handles it's own siderender.
    subject.subscribe(function(snippetRenderItem) {
      self.sideRender(snippetRenderItem.rollup, snippetRenderItem.record);
    });
  }

  return subject;
};

/*
 * Using a page_id, attempt to render the html into the original target id/dom.
 *
 * This is the legacy sideRender that closely matched the original
 * MantleReview/POWERREVIEWS.display.render integration. This is before using
 * the data api which allows us more flexibility.
 */
PowerReviewsApiBroker.prototype.sideRender = function(rollup, record) {
  var page_id = rollup.page_id;
  if (!record) {
    console.warn(page_id + ' was not found in PowerReviewApiBroker.snippet_map');
    return;
  }

  var snippet_id = record.snippet_id;
  var snippet_el = record.snippet_el;

  // we're good to go.
  var $target = $('#' + snippet_id);
  if ($target.length === 0 && !!snippet_el) {
    // Try snippet_el if it exists
    $target = $(snippet_el);
  }

  record.rendered = true;

  // This is a very basic Stars render. Appropriate for CategorySnippet but not
  // for regular ReviewSnippet.
  // TODO: This can be expanded to allow us to render different widgets or even
  // a more enhanded default. However, since the brand code can now explicitly
  // handle all aspects of widget render, this seems less of a priority.
  var renderedStars = MantleReviews.renderStars(rollup);

  $target.html(renderedStars);
};

/*
 * Called during the MantleReview.render func.
 */
PowerReviewsApiBroker.prototype.render = function() {
  var self = this;
  var pageIds = _.keys(this.snippet_map);
  // Note that PR chunks up requests into 10 per request.
  // Basically copying their mechanics.
  var chunks = _.chunk(pageIds, 10);

  _.each(chunks, function(chunk) {
    var subjects = self.api.getSnippets(chunk);

    var callback = function(rollup) {
      var sideRenderSubject = self.siderender_subjecthash.get(rollup.page_id);
      var record = self.snippet_map[rollup.page_id];
      var snippetRenderItem = {
        rollup: rollup,
        record: record
      };
      sideRenderSubject.next(snippetRenderItem);
    };

    for (var pageId in subjects) {
      var subject = subjects[pageId];
      subject.subscribe(callback);
    }
  });
};

/*
 * Broker.getData. Called from MantleReviews.getData.
 */
PowerReviewsApiBroker.prototype.getData = function(page_id) {
  if (page_id) {
    page_id = page_id.toString();
  }

  var subject = this.siderender_subjecthash.get(page_id);
  return subject;
};
