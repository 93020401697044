var MantleSelectBox = (function(MantleSelectBox, $) {
  MantleSelectBox.attach_handlers = function($template, $select) {
    $template.on('change', 'select.js-mantle-select', function() {
      var skuId = $(this).val();
      var sku = prodcat.data.getSku(skuId);
      var skuBaseId;

      if (sku) {
        skuBaseId = sku.SKU_BASE_ID;
        $template.closest('.js-product').trigger('product.skuSelect', [skuBaseId]);
      }
    });


    /*
     *  expose a changeSelectToSku
     */
    $template.on('changeSelectToSku', function(e, $product, skuBaseId) {
      $select.val(skuBaseId);
      if ($select.hasClass('selectBox-attached')) {
        $select.selectBox('value', skuBaseId);
      }
    });
  }

  MantleSelectBox.order_options_by_name = function($select) {
    // see if we were passed a wrapper elem
    if (!$select.is('select.js-mantle-select')) {
      $select = $select.find('select.js-mantle-select');
    }

    if (!$select.is('select.js-mantle-select')) {
      console.log("Was passed an incorrect $select");
      return;
    }

    var selected = $select.val();

    var $options = $('option', $select);
    $options.sort(function(a,b) {
      if (a.text.toUpperCase() > b.text.toUpperCase()) return 1;
      if (a.text.toUpperCase() < b.text.toUpperCase()) return -1;
      return 0
    })

    $select.empty().append($options);
    $select.val(selected);

    var control = $select.data('selectBox-control');
    if (control && control.length) {
      $select.selectBox('refresh');
    }
  }

  MantleSelectBox.attach_sku_selectbox = function($template) {
    var $select = $('select.js-mantle-select', $template);
    var $options = $('option', $select);

    // signify this dom element as the event hub.
    $template.attr('mantle-sku-select-hub', 1);
    $template.attr('data-options-count', $options.length);
    $select.addClass('mantle-select-processed');
    $select.attr('data-options-count', $options.length);

    this.attach_handlers($template, $select);

    // make sure to init correctly to the right sku id
    var $product = $template.closest('.js-product');
    if ($product.data('sku-base-id')) {
      $template.triggerHandler('changeSelectToSku', [$product, $product.data('sku-base-id')]);
    }
  }

  // Likewise, whenever skuSelect is fired on a product, update the menu:
  $(document).on('product.skuSelect', '.js-product', function(event, skuBaseId) {
    var $product = $(this);
    var $listeners = $product.find('[mantle-sku-select-hub="1"]');
    $listeners.triggerHandler('changeSelectToSku', [$product, skuBaseId]);
  });

  return MantleSelectBox;
})(MantleSelectBox || {}, jQuery);
