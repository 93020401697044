var ytScriptTag = document.createElement('script');

ytScriptTag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];

// Adding a check here to ensure that the iframe_api is only loaded once. 
if (!document.querySelector('script[src="https://www.youtube.com/iframe_api"]')) {
  firstScriptTag.parentNode.insertBefore(ytScriptTag, firstScriptTag);
};

var onYouTubeIframeAPIReady = function($) {
  $(document).trigger('youtubeIframeAPI.loaded');
}(jQuery);
