/* globals POWERREVIEWS */
var prodcat = prodcat || {};
prodcat.data = prodcat.data || {};
var JSBoot = JSBoot || {};
var MantlePerf = MantlePerf || {};
var PowerReviewsBroker = PowerReviewsBroker || {};
var PowerReviewsApiBroker = PowerReviewsApiBroker || {};

var MantleReviewsPowerReviews = (function(MantleReviews, $, loadjs) {
  MantleReviews.prBroker = new PowerReviewsApiBroker();

  // Basically keeping around the meta_registry based on page_id
  MantleReviews.meta_registry = {};
  MantleReviews._synthetic_page_ids = {};
  MantleReviews._pr_products = [];
  MantleReviews._pr_components = {};
  MantleReviews._on_render_callbacks = [];
  MantleReviews.minimumTimeout = 500;

  var baseUrl = window.location.protocol + '//' + document.location.hostname;
  var brandSiteName = Drupal.settings?.common?.brand_name;
  var strip_html_tags = function(str) {
    if ((str === null) || (str === '')) {
      return false;
    } else {
      str = str.toString();
      return str.replace(/<[^>]*>/g, '');
    }
  };

  /*
  * Helper function to determine the merchant id and locale
  * based on the locale cookie value
  *
  * TODO: This should be merged with PowerReviewsAPI.getPowerReviewsConfigFromSettings
  */
  MantleReviews.get_power_reviews_configuration = function() {
    var prConfiguration = Drupal.settings.power_reviews.merchant_ids;
    var locale = null;
    if (MantleReviews.locale) {
      locale = MantleReviews.locale;
    }
    if (!locale) {
      locale = JSBoot.cookie.get('LOCALE') || 'en_US';
      locale = locale.toLowerCase();
      MantleReviews.locale = locale;
    }
    var prLocaleMerchantId = [];
    if (typeof prConfiguration[locale] !== 'undefined') {
      $.each (prConfiguration[locale], function(locale, merchantId) {
        prLocaleMerchantId.push({'locale': locale, 'merchant_id': merchantId});
      });
    }
    return prLocaleMerchantId;
  };

  /*
   * Check for valid settings.
   */
  MantleReviews.power_reviews_enabled = function() {
    if (!Drupal.settings.power_reviews) {
      return false;
    }

    // Check that we have a valid merchant id.
    var pr_merchant_id = null;
    if (typeof Drupal.settings.power_reviews.merchant_ids !== 'undefined') {
      var pr_locale_merchant_id = this.get_power_reviews_configuration();
      pr_merchant_id = pr_locale_merchant_id[0].merchant_id;
    } else {
      pr_merchant_id = Drupal.settings.power_reviews.merchant_id;
    }
    return !!pr_merchant_id;
  };

  // for now, want to treat as singleton.
  MantleReviews.ran = false;
  MantleReviews.locale = null;

  MantleReviews._auto_render = true;
  MantleReviews.product_init_called = false;

  MantleReviews.enabled = MantleReviews.power_reviews_enabled();

  /*
   * Allow setting a minimum timeout delay before PR triggers.
   */
  MantleReviews.setMinimumTimeout = function(timeout) {
    if (timeout > this.minimumTimeout) {
      this.minimumTimeout = timeout;
    }
  };

  /*
   * Use loadjs to load the POWERREVIEWS js. This can be called multiple times
   * without loading again.
   *
   * TODO: Would conceptually like to move this out into a more global system
   * of defining async bundles. So a behavior could define what js is needed to
   * load async.
   */
  MantleReviews.loadPRJS = function(callback) {
    var prJs = Drupal.settings.power_reviews.pr_js;

    if (!loadjs.isDefined('powerreviews')) {
      loadjs([prJs], 'powerreviews');
    }
    loadjs.ready('powerreviews', callback);
  };

  MantleReviews._get_product_data = function(product) {
    var imageUrl = typeof product.defaultSku.LARGE_IMAGE === 'object' ? encodeURI(product.defaultSku.LARGE_IMAGE[0]) : encodeURI(product.defaultSku.LARGE_IMAGE);
    var url = encodeURI(product.url);
    var productName = strip_html_tags(product.PROD_RGN_NAME);
    var productDesc = product.DESCRIPTION ? strip_html_tags(product.DESCRIPTION) : strip_html_tags(product.PROD_RGN_SUBHEADING);
    var productUpc = encodeURI(product.defaultSku.UPC_CODE);
    var productCatId = strip_html_tags(product.DEFAULT_CAT_ID);
    var productPrice = encodeURI(product.defaultSku.formattedPrice).replace(/[^.,\d]/g, '');
    var stockCheck = (!!product.isShoppable).toString();

    var product_data = {
      name: productName,
      url: baseUrl + url,
      image_url: baseUrl + imageUrl,
      description: productDesc,
      category_name: productCatId,
      upc: productUpc,
      brand_name: brandSiteName,
      price: productPrice,
      in_stock: stockCheck
    };

    return product_data;
  };

  /* eslint-disable complexity */
  MantleReviews.add_product_info = function(product, pr_product, add_variants) {
    var product_data = this._get_product_data(product);
    if (!pr_product.page_id) {
      pr_product.page_id = product.PROD_BASE_ID;
    }

    add_variants = !!add_variants; // default to false

    // If shaded, add variants.
    var variants = [];
    var skus = product.skus;
    var sku_count = skus.length;
    if (add_variants && product.shaded) {
      for (var s = 0; s < sku_count; s++) {
        if (!skus[s].SHADENAME) {
          continue;
        }
        variants.push({
          'name': skus[s].SHADENAME,
          'description': skus[s].SHADE_DESCRIPTION ? skus[s].SHADE_DESCRIPTION : skus[s].SHADENAME,
          'price': skus[s].PRICE,
          'upc': skus[s].UPC_CODE,
          'page_id_variant': skus[s].SKU_ID
        });
      }
    }

    product_data.variants = variants;

    pr_product.product = product_data;
  };

  /*
   * Returns a basic pr_product
   */
  MantleReviews.get_base_pr_product = function() {
    var pr_locale, pr_merchant_id;
    if (typeof Drupal.settings.power_reviews.merchant_ids !== 'undefined') {
      var pr_locale_merchant_id = this.get_power_reviews_configuration();
      pr_locale = pr_locale_merchant_id[0].locale;
      pr_merchant_id = pr_locale_merchant_id[0].merchant_id;
    } else {
      pr_locale = Drupal.settings.power_reviews.locale;
      pr_merchant_id = Drupal.settings.power_reviews.merchant_id;
    }

    // base pr_product
    var pr_product = {
      api_key: Drupal.settings.power_reviews.api_key,
      locale: pr_locale,
      merchant_group_id: Drupal.settings.power_reviews.merchant_group_id,
      merchant_id: pr_merchant_id,
      components: {
      }
    };

    return pr_product;
  };

  /*
   * Check if we have POWERREVIEWS loaded and the drupal settings have PR
   * enabled.
   */
  MantleReviews.is_setup = function() {
    if (!window.POWERREVIEWS) {
      return false;
    }
    if (typeof Drupal.settings.power_reviews === 'undefined') {
      return false;
    }
    return true;
  };

  /*
   * A pr_component is essentially a snippet that rides along with a full
   * pr_product like ReviewDisplay. ReviewDisplay triggers the render callback
   * while ReviewSnippet does not.
   *
   * Normally you can define the components for a page_id in one pass but for
   * places like an SPP, the ReviewSnippet might appear in a completely
   * different template than the main ReviewDisplay. Having this pr_component
   * system allows that ReviewSnippet to be better encapsulated and allows the
   * template js request the PR info instead of having a central js file that
   * needs to know what dom elements to target.
   */
  MantleReviews.add_pr_product_component = function(page_id, component_type, target_id) {
    page_id = page_id.toString();
    if (typeof this._pr_components[page_id] === 'undefined') {
      this._pr_components[page_id] = {};
    }
    if (component_type in this._pr_components[page_id]) {
      // eslint-disable-next-line no-console
      console.error('Tried to add duplicate compoment to same page_id');
      return;
    }
    this._pr_components[page_id][component_type] = target_id;
  };

  MantleReviews.add_pr_product = function(pr_product) {
    this._pr_products.push(pr_product);
  };

  /*
   * If for whatever reason you need to disable the auto render, you can turn
   * it off. Whoever is turning off auto render MUST explicitly call render on
   * their own.
   * This is useful because PR can only render items *once*.
   */
  MantleReviews.set_auto = function(auto_enable) {
    self._auto_render = !!auto_enable;
  };

  /*
   * This should be called when rendering the PR async. The primary difference
   * from render is that if product_init has not been called. We will turn on
   * auto_render and wait for that instead of rendering immediately.
   */
  MantleReviews.renderAsync = function(force) {
    if (!this.is_setup()) {
      return;
    }

    // product_init has not been called and auto render is on. So do nothing
    // and let auto render take care of rendering.
    if (!this.product_init_called && !force) {
      this.set_auto(true);
      // eslint-disable-next-line no-console
      console.info('PR auto render is on. Will wait for auto render.');
      return;
    }

    this.render();
  };

  /*
   * Main render function.
   */
  MantleReviews.render = function(force, isAuto) {
    if (!this.product_init_called && !force) {
      /* eslint-disable no-console */
      console.log('MantleReviews.render called before product-init. ' +
      'If this was done on purpose, call with force = true');
      return;
    }

    /*
     * Since we're leaving it up to the brand code to set the page_id.
     * We create the review_wrapper_url as late as possible.
     */
    var self = this;
    _.each(this._pr_products, function(pr_product) {
      var meta = pr_product.mantle_meta || {};
      var metaRegistry = self.meta_registry || {};
      delete pr_product.mantle_meta;
      self.process_pr_product(pr_product, meta);
      var page_id = pr_product.page_id;
      // this should probably be moved to a validation loop
      if (!page_id) {
        return;
      }
      // Make sure page_id is a string
      if (!isNaN(page_id)) {
        page_id = page_id.toString();
      }

      pr_product.page_id = page_id;
      // store meta for later use.
      meta.components = pr_product.components;
      if (_.isUndefined(metaRegistry[page_id])) {
        metaRegistry[page_id] = meta;
      }

      // Attach the synethic page_id right before we send to PR.
      if (pr_product.synthetic_page_id) {
        var synthetic_page_id = pr_product.synthetic_page_id;
        pr_product.page_id = synthetic_page_id;
        self._synthetic_page_ids[synthetic_page_id] = page_id;
      }

      self.prBroker.addCategorySnippet(pr_product);
    });

    // prDisplayRender holds the items that need POWERREVIEWS.display.render
    var prDisplayRender = [];
    _.each(this._pr_products, function(pr_product) {
      if (pr_product.components && !_.isEmpty(pr_product.components)) {
        prDisplayRender.push(pr_product);
      }
    });

    if (this.prBroker.render && typeof this.prBroker.render === 'function') {
      this.prBroker.render();
    }

    // If this is an auto called func and auto_render is set to False,
    // we do not call the PR render func. We will still render any non
    // POWERREVIEWS.display.render widget like CategorySnippets powered by api
    // calls.
    if (isAuto && !this._auto_render) {
      this._pr_products = prDisplayRender;
      return;
    }

    // Load the Power Reviews JS and run.
    if (prDisplayRender.length > 0) {
      this.loadPRJS(function() {
        POWERREVIEWS.display.render(prDisplayRender);
      });
    }

    this._pr_products = [];
  };

  /*
   * Prelim data api.
   *
   * TODO: generalize this for all power reviews. Not just snippets
   */
  MantleReviews.getData = function(page_id) {
    if (page_id) {
      page_id = page_id.toString();
    }

    var subject = MantleReviews.prBroker.getData(page_id);
    return subject;
  };

  MantleReviews.get_pr_meta = function(page_id) {
    var meta = this.meta_registry[page_id] || {};

    if (this._synthetic_page_ids[page_id]) {
      var synthetic_page_id = this._synthetic_page_ids[page_id];
      meta = this.meta_registry[synthetic_page_id] || {};
    }
    return meta;
  };

  /*
   * Function that runs the pr_product processing functions.
   * TODO: add hook to allow brands to add custom processing.
   */
  MantleReviews.process_pr_product = function(pr_product, meta) {
    this.process_pr_product_wrapper_url(pr_product, meta);
    this.process_pr_product_add_product_info(pr_product, meta);
    this.process_pr_product_on_render(pr_product, meta);
    this.process_pr_product_sub_components(pr_product, meta);
  };

  MantleReviews.process_pr_product_sub_components = function(pr_product, meta) {
    var page_id = pr_product.page_id;
    if (!this._pr_components[page_id]) {
      return;
    }
    var components = this._pr_components[page_id];
    delete this._pr_components[page_id];
    $.each(components, function(component_type, target_id) {
      pr_product.components[component_type] = target_id;
    });
  };

  /*
   * Late binding to the page_id. Since the brand code is responsible for
   * generating the page_id. We auto generate the wrapper url on render. This
   * is to require the least amount of brand code to intgrate.
   */
  MantleReviews.process_pr_product_wrapper_url = function(prProduct, meta) {
    // only handle when review_wrapper_url is empty.
    if (prProduct.review_wrapper_url) {
      return;
    }
    // note that powerreivews adds the conf vars to url
    var pageId = prProduct.page_id;
    var productBaseId = meta.product_base_id || pageId;
    prProduct.review_wrapper_url = this.generateReviewWrapperUrl(pageId, productBaseId);
  };

  /*
   * An easy way to generate the full write review url from a prPRoduct.
   */
  MantleReviews.generateFullWriteReviewUrlFromPRProduct = function(prProduct) {
    var pageId = prProduct.page_id;
    var productBaseId = prProduct.product_base_id;
    var merchantId = prProduct.merchant_id;
    var merchantGroupId = prProduct.merchant_group_id;
    var apiKey = prProduct.api_key;
    var fullUrl = this.generateFullWriteReviewUrl(
      pageId,
      productBaseId,
      merchantId,
      merchantGroupId,
      apiKey
    );
    return fullUrl;
  };

  /*
   * Quick Util to generate the Review wrapper url.
   *
   * This explicitly only takes pageId and productBaseId for use outside of the
   * MantelReviews system.
   */
  MantleReviews.generateReviewWrapperUrl = function(pageId, productBaseId) {
    var reviewWrapperUrlBase = Drupal.settings.power_reviews.review_wrapper_url || '/review/create';
    var qs = '?page_id=' + pageId + '&prod_id=' + productBaseId;
    var wrapperUrl = reviewWrapperUrlBase + qs;
    return wrapperUrl;
  };


  /*
   * Replicate the write link generated by the power reviews library.
   *
   * It appears to be the review wrapper url plus the following query params:
   *
   * pr_merchant_id
   * pr_merchant_group_id
   * pr_api_key
   * pr_page_id
   */
  MantleReviews.generateFullWriteReviewUrl = function(pageId, productBaseId, merchantId, merchantGroupId, apiKey) {
    var baseUrl = this.generateReviewWrapperUrl(pageId, productBaseId);
    var fullUrl = baseUrl + '&pr_page_id' + pageId;
    if (merchantId) {
      fullUrl += '&pr_merchant_id=' + merchantId;
    }
    if (merchantGroupId) {
      fullUrl += '&pr_merchant_group_id=' + merchantGroupId;
    }
    if (apiKey) {
      fullUrl += '&pr_api_key=' + apiKey;
    }
    return fullUrl;
  };

  /*
   * Attach a on_render function that calls any existing on_render and also
   * runs a global handler.
   */
  MantleReviews.process_pr_product_on_render = function(pr_product) {
    var _old_on_render = pr_product.on_render;
    var _on_render = function(config, data) {
      if (_old_on_render) {
        _old_on_render(config, data);
      }
      MantleReviews.handle_on_render(config, data);
    };
    pr_product.on_render = _on_render;
  };

  /*
   * Add prodcat product info to the product. Since render normally occurs
   * after L2 returns, we attach the product info here to make sure it is up to
   * date.
   */
  MantleReviews.process_pr_product_add_product_info = function(pr_product, meta) {
    if (!meta.add_product_info) {
      return;
    }
    if (!meta.product_id) {
      console.log('ERROR: add_product_info flagged without providing product_id');
      return;
    }
    var add_variants = !!meta.add_product_variants;
    var product_id = meta.product_id;
    var product = prodcat.data.getProduct(product_id);
    this.add_product_info(product, pr_product, add_variants);
  };

  /*
   * Add a global on_render. Would be more useful if config returned the
   * target_id for the component.
   */
  MantleReviews.add_global_render_callback = function(callback) {
    this._on_render_callbacks.push(callback);
  };

  MantleReviews.handle_on_render = function(config, data) {
    if (config.page_id) {
      var meta = this.get_pr_meta(config.page_id);

      this.global_on_render_process(config, data, meta);
    }

    $.each(this._on_render_callbacks, function(i, func) {
      func(config, data);
    });
  };

  /*
   * Global on render callbacks
   */
  MantleReviews.global_on_render_process = function(config, data, meta) {
    this._global_on_render_process_stars(config, data, meta);
    this._global_on_render_process_sort_selectBox(config, data, meta);
    this._global_on_render_process_search_placeholder(config, data, meta);
  };

  /*
   * On render hook that changes the Star verbiage.
   */
  MantleReviews._global_on_render_process_stars = function(config, data, meta) {
    if (config.component === 'ReviewDisplay') {
      return;
    }

    if (!meta.new_star_label && !meta.new_star_tooltip) {
      return;
    }

    if (!meta.review_display_element) {
      console.warn('Requested replacing Star verbiage but didnt provide element');
      return;
    }

    var $template = $(meta.review_display_element);
    var new_star_label = meta.new_star_label;
    var new_star_tooltip = meta.new_star_tooltip;
    /*
     * Adjust Histogram labels/tooltips to change stars to Hearts
     */
    if (new_star_label) {
      var $labels = $('.pr-histogram-label', $template);
      $labels.each(function(i, obj) {
        var $label = $(obj);
        var text = $label.html();
        var heart_text = text.replace(/Star/g, new_star_label);
        $label.html(heart_text);
      });
    }

    if (new_star_tooltip) {
      var $tooltips = $('.pr-ratings-histogram-bar-container', $template);
      $tooltips.each(function(i, obj) {
        var $tooltip = $(obj);
        var tooltip_text = $tooltip.data('tooltip');
        var heart_tooltip_text = tooltip_text.replace(/star/g, new_star_tooltip);
        $tooltip.attr('data-tooltip', heart_tooltip_text);
      });
    }
  };

  /*
   * Attach selectBox to the ReviewDisplay.
   */
  MantleReviews._global_on_render_process_sort_selectBox = function(config, data, meta) {
    if (config.component === 'ReviewDisplay') {
      return;
    }

    if (!meta.sort_attach_selectbox) {
      return;
    }

    if (!meta.review_display_element) {
      console.warn('Requested using selectBox but didnt provide element');
      return;
    }

    var $template = $(meta.review_display_element);
    /*
     * Attaching selectBox to the ReviewDisplay.
     */
    $('.pr-rd-main-header-sorts-w-search select', $template).each(function(i, obj) {
      var $select = $(obj);
      var onMobile = $select.hasClass('selectBox--yes-even-for-mobile');
      if (meta.sort_attach_selectbox_mobile) {
        onMobile = true;
      }
      $select.addClass('selectBox-attached').selectBox({
        mobile: onMobile,
      });
      $select.selectBox().on('change', function(e) {
        // so selectBox doesn't trigger the proper change event for
        // Power Reviews. We trigger it and make the event so we don't
        // try to retrigger and go into an infinite loop.
        if (e.originalEvent && e.originalEvent.fake_native) {
          return;
        }
        var event = new Event('change', {'bubbles': true});
        event.fake_native = true;
        obj.dispatchEvent(event);
      });
    });
  };

  /*
   * Replace Search input placeholder
   */
  MantleReviews._global_on_render_process_search_placeholder = function(config, data, meta) {
    if (config.component === 'ReviewDisplay') {
      return;
    }

    if (!meta.search_placeholder) {
      return;
    }

    if (!meta.review_display_element) {
      console.warn('Requested using selectBox but didnt provide element');
      return;
    }

    var $template = $(meta.review_display_element);
    $('.pr-rd-search-reviews-input input', $template).attr('placeholder', meta.search_placeholder);
  };
  /*
   * Helper function that will attach PR ratings to the parent selector
   * element. Used for attaching PR data to product grids.
   */
  MantleReviews.surface_rating = function(selector) {
    var $elements = $(selector);
    $elements.each(function() {
      var $elem = $(this);
      var $prSnippet = $('.pr-snippet-rating-decimal', $elem);
      if ($prSnippet.length) {
        $elem.attr('data-rating-sort', $prSnippet.text());
      }
    });
  };

  /*
   * Given a PowerReviews snippet rollup object. Render a faux power review
   * stars snippet.
   */
  MantleReviews.renderStars = function(rollup, template_name) {
    var averageRating = rollup.average_rating;
    var reviewCount = rollup.review_count;

    var data = MantleReviews.processStarsData(averageRating, reviewCount);

    if (!template_name) {
      template_name = 'pr_review_stars';
    }

    var renderedStars = site.template.get({
      name: template_name,
      data: data
    });

    return renderedStars;
  };

  /*
   * Create the template variables needed for pr_review_stars_v1.
   *
   * Replicates mantle_reviews_review_stars_alter() in Drupal.
   */
  MantleReviews.processStarsData = function(rating, review_count) {
    var data = [];
    var starPack = [];

    var starSteps = [0, 25, 50, 75, 100];

    if (!rating) {
      rating = 0;
    }

    // Choose the value closest to a starStep. So 4.45 will result in:
    // [100, 100, 100, 100, 50]
    for (var i = 0; i < 5; i++) {
      var starDiff = (rating - i) * 100;
      var lastStepDiff = starDiff;
      var starValue = null;
      for (var k = starSteps.length; k >= 0; k--) {
        var starStepValue = starSteps[k];
        var currentStepDiff = Math.abs(starDiff - starStepValue);
        if (currentStepDiff > lastStepDiff) {
          break;
        }
        lastStepDiff = currentStepDiff;
        starValue = starStepValue;
      }
      starPack.push(starValue);
    }

    data.rating = rating;
    data.review_count = review_count;
    data.star_pack = starPack;
    return data;
  };

  // Event Listeners.
  $(window).on('load', function() {
    MantleReviews.surface_rating('.js-product-grid-item--sortable');
  });

  $(document).on('endeca.search.results.loaded', function() {
    MantleReviews.surface_rating('.js-product-grid-item--sortable');
  });

  // Render power reviews after products have been initialized
  $(document).on('products:init-finish', function() {
    var autoloadReviewsFunc = function() {
      MantleReviews.product_init_called = true;
      MantleReviews.render(false, true);
    };

    // Make sure we run after behaviors
    // For large mpps, we push off rendering the ratings so there isn't a
    // stutter.
    $(function() {
      var productCounts = MantleReviews._pr_products.length;
      var timeout = productCounts * 5;
      if (timeout < MantleReviews.minimumTimeout) {
        timeout = MantleReviews.minimumTimeout;
      }

      if (MantleReviews.disableTimeout) {
        timeout = 0;
      }
      window.setTimeout(MantlePerf.thresholdLogWrap(autoloadReviewsFunc), timeout);
    });
  });

  // TODO: so the below might not be globally available. we'll need to wrap this
  // into util funcs that brand code can run.
  $(document).on('quickshop.loaded', function() {
    var $quickShop = $('.js-quickshop', $('#cboxLoadedContent'));
    var productID = $quickShop.length ? $quickShop.data('productId') : '';
    if (productID !== '') {
      var $quickSnippet = $('.review-snippet', $quickShop);
      $quickSnippet.html($('div.review-snippet[data-product-id=' + productID + ']:not(:empty)').html());
    }
  });

  $(document).on('product.quickshop.opened', function() {
    var $quickShop = $('.js-quickshop-container.active');
    var productID = $('.js-quickshop', $quickShop).length ? $('.js-quickshop', $quickShop).data('productId') : '';
    if (productID !== '') {
      var $quickSnippet = $('.review-snippet', $quickShop);
      $quickSnippet.html($('div.review-snippet[data-product-id=' + productID + ']:not(:empty)').html());
    }
  });

  return MantleReviews;
})(window.MantleReviews || {}, jQuery, window.loadjs);
